import { GET, POST, PUT, DELETE} from './http-wrapper';
import {
  PUBLIC_HEADER_TOKEN,
  ServiceURL,
} from '../constants';
import { GetCookie, getBrowserDetails } from './helper';

const GetHeader = (isPrivate = false) => {
  let headers = {};
  if (isPrivate) {
    const accessToken = GetCookie('AffiliateAccessToken');
    headers['Authorization'] = accessToken;
  } else {
    // Public API needs PUBLIC_AUTHORIZATION_TOKEN as header
    headers['Authorization'] = PUBLIC_HEADER_TOKEN;
  }
  return headers;
};

export const LoginAPI = (data) => {
  const url = `${ServiceURL}/login`;
  return POST(url, GetHeader(), data);
};

export const ForgotPwd = (data) => {
  const url = `${ServiceURL}/forgot-password`;
  // Public API needs PUBLIC_AUTHORIZATION_TOKEN as header
  const headers = {};
  headers['Authorization'] = PUBLIC_HEADER_TOKEN;
  return POST(url, headers, data);
};

export const ResetPwd = (data) => {
  const url = `${ServiceURL}/reset-password`;
  // Public API needs PUBLIC_AUTHORIZATION_TOKEN as header
  const headers = {};
  headers['Authorization'] = PUBLIC_HEADER_TOKEN;
  return POST(url, headers, data);
};

export const GetAffiliateDetails = (id) => {
  let url = `${ServiceURL}/affiliate/${id}`;
  return GET(url, GetHeader(true), null);
};

export const GetAffiliateVehicles = (id) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicles`;
  return GET(url, GetHeader(true), null);
};

export const UpdateAffiliateDetails = (id, data) => {
  let url = `${ServiceURL}/affiliate/${id}`;
  return PUT(url, GetHeader(true), data);
};

export const GetAffiliateContacts = (id) => {
  let url = `${ServiceURL}/affiliate/${id}/contacts`;
  return GET(url, GetHeader(true), null);
};

export const GetLeads = (params) => {
  let url = `${ServiceURL}/affiliate/leads`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
};

export const GetLeadDetails = (id) => {
  let url = `${ServiceURL}/affiliate/lead/${id}`;
  return GET(url, GetHeader(true), null);
};

// export const UpdateLeadDetails = (id, data) => {
//   let url = `${ServiceURL}/lead/${id}`;
//   return PUT(url, GetHeader(true), data);
// };

// export const FetchLocation = async (zipcode) => {
//   try {
//     // Validate if the zipcode is in USA format (5 digits)
//     const isValidZip = /^\d{5}$/.test(zipcode);
//     if (!isValidZip) {
//       // console.log('Invalid USA zip code');
//       return false;
//     }

//     const response = await fetch(
//       `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipcode}.json?country=US&access_token=${MAPBOX_ACCESS_TOKEN}`
//     );
//     const locationData = await response.json();

//     // Extract city and state from the response
//     const features = locationData.features;
//     if (features.length > 0) {
//       const place = features[0];
//       const city = place.context.find((context) =>
//         context.id.includes('place')
//       );
//       const state = place.context.find((context) =>
//         context.id.includes('region')
//       );
//       if (city && state) {
//         return {
//           city,
//           state,
//         };
//       }
//     }
//     return null;
//   } catch (error) {
//     console.error('Error fetching location:', error);
//   }
// };

// export const FetchLocationSuggestions = async (query) => {
//   try {
//     const response = await fetch(
//       `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?autocomplete=true&country=US&types=place%2Clocality%2Cpostcode&access_token=${MAPBOX_ACCESS_TOKEN}`
//     );
//     const locationData = await response.json();
//     return locationData;
//   } catch (error) {
//     console.error('Error fetching location:', error);
//   }
// };

export const GetMatchingVehicle = (id) => {
  let url = `${ServiceURL}/affiliate/lead/${id}/requests`;
  return GET(url, GetHeader(true), null);
}

// export const GetLeadRequests = (params) => {
//   let url = `${ServiceURL}/leads/requests`;
//   if (params) {
//     url = url + '?' + params;
//   }
//   return GET(url, GetHeader(true), null);
// };

// export const GetLeadResponses = (params) => {
//   let url = `${ServiceURL}/leads/responses`;
//   if (params) {
//     url = url + '?' + params;
//   }
//   return GET(url, GetHeader(true), null);
// };

export const LogOut = (token) => {
  const url = `${ServiceURL}/logout`;
  const headers = {};
  headers['Authorization'] = token;
  return POST(url, headers, null);
}

// export const GetLeadVehicleDetails = (id) => {
//   let url = `${ServiceURL}/lead/${id}/requests`;
//   return GET(url, GetHeader(true), null);
// };

// export const DeleteLead = (id) => {
//   let url = `${ServiceURL}/lead/${id}`;
//   return DELETE(url, GetHeader(true), null);
// }

export const DeleteAffiliate = (id) => {
  let url = `${ServiceURL}/affiliate/${id}`;
  return DELETE(url, GetHeader(true), null);
}

// export const HireVehicle = (leadId, requestId, data) => {
//   let url = `${ServiceURL}/lead/${leadId}/request/${requestId}/hire-vehicle`;
//   return POST(url, GetHeader(true), data);
// }

// export const SendAffiliateReminder = (data) => {
//   const payload = {
//     'requests': data
//   }
//   let url = `${ServiceURL}/requests/notify`;
//   return POST(url, GetHeader(true), payload);
// }

export const CreateVehicle = (id, data) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicle`;
  return POST(url, GetHeader(true), data);
}

export const EditVehicle = (id, vehicleId, data) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicle/${vehicleId}`;
  return PUT(url, GetHeader(true), data);
}

export const DeleteVehicle = (id, vehicleId) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicle/${vehicleId}`;
  return DELETE(url, GetHeader(true), null);
}

export const CreateContact = (id, data) => {
  let url = `${ServiceURL}/affiliate/${id}/contact`;
  return POST(url, GetHeader(true), data);
}

export const EditContact = (id, contactId, data) => {
  let url = `${ServiceURL}/affiliate/${id}/contact/${contactId}`;
  return PUT(url, GetHeader(true), data);
}

export const DeleteContact = (id, contactId) => {
  let url = `${ServiceURL}/affiliate/${id}/contact/${contactId}`;
  return DELETE(url, GetHeader(true), null);
}

export const RegisterAffiliate = (data) => {
  let url = `${ServiceURL}/affiliate/register`;
  return POST(url, GetHeader(), data);
}

export const GetSentQuotes = (params) => {
  let url = `${ServiceURL}/affiliate/leads/sent-quotes`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
}

export const SetLeadResponse = (id, data) =>{
  let url = `${ServiceURL}/affiliate/lead/${id}/responses`;
  return POST(url, GetHeader(true), data);
}

export const UpdateLeadResponse = (id, data) =>{
  let url = `${ServiceURL}/affiliate/lead/${id}/responses`;
  return PUT(url, GetHeader(true), data);
}

export const SetFirebaseToken = (token) =>{
  const browserDetails = getBrowserDetails();
  const payload = {
    "token": token,
    "os_name": browserDetails.browserName,
    "os_version":browserDetails.version,
    "device_name": browserDetails.userAgent,
    "device_uuid": "na"
  }
  let url = `${ServiceURL}/push-notification-token`;
  return POST(url, GetHeader(true), payload);
}

export const GetAffiliateStatus = () => {
  let url = `${ServiceURL}/affiliate/status`;
  return GET(url, GetHeader(true), null);
}

export const CreateRequest = (id, data) =>{
  let url = `${ServiceURL}/affiliate/lead/${id}/requests`;
  return POST(url, GetHeader(true), data);
}

export const TrackLead = (id)=>{
  let url = `${ServiceURL}/lead/${id}/read`;
  return POST(url, GetHeader(true), null);
}