// Import Module
import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Col, message } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import SideCarousel from '../components/side_carousel';
import { SetCookie, GetCookie, ValidateEmail } from '../services/helper';
import { LoginAPI } from '../services/api';
import logo from '../assets/images/price4limo-logo.jpg';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  useEffect(() => {
    const accessToken = GetCookie('AffiliateAccessToken');
    if (accessToken) {
      navigate('/leads');
    }
  }, [navigate]);

  const onLogin = () => {
    if(!email || email.trim() === '') {
      messageApi.open({
        key,
        type: 'error',
        content: 'Email is required!',
      });
    }
    else if(!password){
      messageApi.open({
        key,
        type: 'error',
        content: 'Password is required!',
      });
    }
    else if (!ValidateEmail(email)) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Invalid email!',
      });
    }
    else if (password.trim().length < 8 || password.includes(' ')) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Password should be minimum of 8 characters and cannot contain white spaces!',
      });
    }
    else {
      const data = {
        email: email.trim(),
        password: password.trim()
      };
      setLoading(true);
      LoginAPI(data)
        .then((d) => {
          setLoading(false);
          if (d.code === 200) {
            const { token, refreshToken } = d?.data?.tokens;
            const { name, affiliate_id } = d?.data?.user_details;
            messageApi.open({
              key,
              type: 'success',
              content: `Welcome ${name}!`,
            });
            if (token) {
              SetCookie('AffiliateAccessToken', token, 30);
              SetCookie('AffiliateRefreshToken', refreshToken, 30);
              SetCookie('AffiliateUser', name, 30);
              SetCookie('AffiliateID', affiliate_id, 30);
              setTimeout(() => {
                // Since socket connects on page reload doing this
                window.location.href = "/leads"
                // navigate('/leads');
              }, 1000);
            }
          } else {
            messageApi.open({
              key,
              type: 'error',
              content: 'Email or Password seems to be invalid.',
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          messageApi.open({
            key,
            type: 'error',
            content: e.message,
          });
        });
    }
  };

  return (
    <div className='auth-wrapper'>
      <SideCarousel />
      {contextHolder}
      <Col className='auth-section'>
        <Form>
          <div className='login-logo-wrapper'>
            <img src={logo} alt='price 4 limo' className='login-logo' />
          </div>
          <h1>Partners login</h1>
          <p>Please login to continue</p>
          <div className='mb-3'>
            <label>Email address</label>
            <Input
              placeholder='Enter email address'
              size='large'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // maxLength={48}
              prefix={<MailOutlined className='site-form-item-icon' />}
            />
          </div>
          <div className='mb-3'>
            <label>Password</label>
            <Input.Password
              placeholder='Enter password'
              size='large'
              className='form-control'
              value={password}
              maxLength={48}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<LockOutlined className='site-form-item-icon' />}
            />
          </div>
          <div className='mb-3 flex-separate'>
            <div className='custom-control custom-checkbox'>
              <p className='forgot-password text-right'>
                Don't have an account? &nbsp;<a href='/register' className='ant-btn-link'>Sign Up</a>
              </p>
            </div>
            <p className='forgot-password text-right'>
              <a className='ant-btn-link' href='/forgot-password'>Forgot password?</a>
            </p>
          </div>
          <Button
            type='primary'
            block={true}
            loading={loading}
            className='btn btn-primary'
            size='large'
            onClick={onLogin}
          >
            Login
          </Button>
        </Form>
      </Col>
    </div>
  );
};

export default Login;
