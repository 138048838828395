// Import Modules
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { message } from 'antd';
import PropTypes from 'prop-types';

import PrivateRoute from './components/private_route';
import BurgerMenu from './components/burger_menu';

// All the scene of routing
import Home from './scenes/home';
import Login from './scenes/login';
import ForgotPassword from './scenes/forgot-password';
import ResetPassword from './scenes/reset-password';
import AffiliateResetPassword from './scenes/affiliate-reset-password';
import Leads from './scenes/leads';
import LeadDetails from './scenes/lead-details';
import AccountSettings from './scenes/account-settings';
import CompanyInfo from './scenes/company-info';
import MyResponse from './scenes/my-response';
import Vehicles from './scenes/vehicles';
import Error404 from './scenes/error404';
import TeamManagement from './scenes/team-management';

import { Permission } from './components/authorization';
import { GetCookie } from './services/helper';
import Register from './scenes/register';

import socket from './socket.js';

const LeadsComponent = Permission.Admin(Leads);
const LeadDetailsComponent = Permission.Admin(LeadDetails);
const CompanyInfoComponent = Permission.Admin(CompanyInfo);
const ResponsesComponent = Permission.Admin(MyResponse);
const AccountSettingsComponent = Permission.Admin(AccountSettings);
const TeamManagementComponent = Permission.Admin(TeamManagement);
const VehiclesComponent = Permission.Admin(Vehicles);

const RouteList = () => {
  // class RouteList extends React.Component {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const accessToken = GetCookie('AffiliateAccessToken');
    // If Cookie contains Access_Token or Refresh Token then access website
    if (accessToken) {
      setIsLoggedIn(true);
    }
    // If Cookie is not there then redirect it to login page
    else {
      setIsLoggedIn(false);
      // console.log('location', location.pathname);
      // const privateURLs = ['/leads', '/lead', '/company-info', '/responses', '/vehicles', '/team-management', '/account-settings'];
      // if (privateURLs.includes(location.pathname)) {
      //   window.location.href = '/login';
      // }
    }
  }, [location]);

  const newLeadReceived = () => {
    messageApi.open({
      duration: 0,
      key: "key",
      type: 'warning',
      content: "You've received new lead, click here to view it",
      onClick: () => {window.location.href = "/leads" },
      style: {"cursor": "pointer"}
    });
  };

  useEffect(() => {
    // Connect socket only if user is logged in
    const accessToken = GetCookie('AffiliateAccessToken');
    const affiliateId = GetCookie('AffiliateID');

    if(accessToken){
      // Connecting socket events
      socket.connect();

      // Event on socket connection
      socket.on('connect', () => {
        socket.emit('join', `affiliate_${affiliateId}`);
      });

      // Listen to the new quotes coming in
      socket.on('new_price_request', () => {
        newLeadReceived();
      }); 
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoggedIn && <BurgerMenu />}
      <div id={isLoggedIn ? 'outer-container' : ''}>
        {contextHolder}
        <Routes>
          <Route exact path='/forgot-password' element={<ForgotPassword />} />
          <Route exact path='/' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route
            exact
            path='/reset-password/:token'
            element={<ResetPassword />}
          />
          <Route
            exact
            path='/affiliate/reset-password/:token'
            element={<AffiliateResetPassword />}
          />
          <Route
            exact
            path='/affiliate/reset-password-web/:token'
            element={<ResetPassword />}
          />
            <>
              <Route exact path='/leads' element={<PrivateRoute />}>
                <Route exact path='/leads' element={<LeadsComponent />} />
              </Route>
              <Route exact path='/lead/:id' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/lead/:id'
                  element={<LeadDetailsComponent />}
                />
              </Route>
              <Route exact path='/company-info' element={<PrivateRoute />}>
                <Route exact path='/company-info' element={<CompanyInfoComponent />} />
              </Route>
              <Route exact path='/responses' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/responses'
                  element={<ResponsesComponent />}
                />
              </Route>
              <Route exact path='/vehicles' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/vehicles'
                  element={<VehiclesComponent />}
                />
              </Route>
              <Route exact path='/team-management' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/team-management'
                  element={<TeamManagementComponent />}
                />
              </Route>
              <Route exact path='/account-settings' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/account-settings'
                  element={<AccountSettingsComponent />}
                />
              </Route>
            </>
          <Route path='*' element={<Error404 />} />
        </Routes>
      </div>
    </>
  );
};

RouteList.propTypes = {
  setThemeMode: PropTypes.func,
};

RouteList.defaultProps = {
  setThemeMode: () => { },
};

export default RouteList;
