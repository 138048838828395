import React from 'react'

const TripIcon = () => {
    return (
        <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1366_5383)">
                <path d="M1 1H23" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 8H23" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 15H14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1366_5383">
                    <rect width="24" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TripIcon;