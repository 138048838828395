// Import Module
import React from "react";
import { Radio, Input } from 'antd';

import {  } from "../services/helper";
import PhoneInput from './phone_input';

const CompanyDetails = ({data, setData}) => {

  const updateData = (key, value) => {
    let _data = { ...data };
    if (key === 'street_address' || key === 'zipcode') {
      if (!_data['address']) {
        _data['address'] = {};
      }
      _data['address'][key] = value;
    } else if(key === 'email') {
      _data[key] = value.trim(); // to remove white spaces
    } else {
      _data[key] = value;
    }
    setData(_data);
  };

  return (
    <div>
      <div className='add-affiliate-form'>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>Company Name</div>
          <Input placeholder='Enter company name' maxLength={60} value={data.name} onChange={(e) => updateData('name', e.target.value)} />
        </div>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>Full address of company</div>
          <Input placeholder='Enter address' maxLength={250} value={data?.address?.street_address} onChange={(e) => updateData('street_address', e.target.value)} />
        </div>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>Zip Code</div>
          <Input placeholder='Enter Zip code' maxLength={5} 
            value={data?.address?.zipcode} 
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
              }
            }}
            onChange={(e) => updateData('zipcode', e.target.value)} />
        </div>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>Office Phone Number</div>
          <PhoneInput  value={data?.phone_number} onChange={(e) => updateData('phone_number', e)}/>
        </div>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>Main Company Email</div>
          <Input placeholder='Enter email address' 
            // maxLength={48} 
            value={data.email} onChange={(e) => updateData('email', e.target.value)} />
        </div>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>Company Website</div>
          <Input placeholder='Enter website' value={data.website} maxLength={320} onChange={(e) => updateData('website', e.target.value)} />
        </div>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>DOT Number (if applicable)</div>
          <Input placeholder='Enter number' value={data.dot_number} maxLength={10} 
            onChange={(e) => updateData('dot_number', e.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
              }
            }} />
        </div>
        <div className='add-affiliate-form-item'>
          <div className='add-affiliate-form-item-label'>Are you properly insured to cross state lines</div>
          <Radio.Group name="radiogroup" value={data?.is_cross_state_insured} onChange={(e) => updateData('is_cross_state_insured', e.target.value)}>
            <Radio value={'Yes'}>Yes</Radio>
            <Radio value={'No'}>No</Radio>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
