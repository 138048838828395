import React, { useMemo, useState } from 'react';
import { Button, message} from 'antd';
import { TripType, VehicleSeat, VehicleColor, VehicleCategorySingularName } from '../../constants';
import { FormatTripHours } from '../../services/helper';
import PassengerCount from '../../assets/images/svg/PassengerCount';
import HiredCheck from '../../assets/images/svg/HiredCheck';
import Seat from '../../assets/images/svg/Seat';
import ThinTime from '../../assets/images/svg/ThinTime';
import { UpdateLeadResponse, SetLeadResponse } from '../../services/api';

import QuotePrice from '../quote_price';

const VehicleQuoteComponent = ({
  canModify = true,
  fromSentQuote,
  request = {},
  lead,
  index,
  addQuote,
  otherMatchingVehicles,
  roundTripData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  const showQuoteLabel = (quoteItem) => {
    if (quoteItem.hours) {
      return FormatTripHours(quoteItem.hours);
    } else {
      // Assuming LeadTripTypeLabel and lead are defined somewhere
      return TripType[lead.trip_type];
    }
  };

  // Date formating to MM/DD/YYYY HH:MM AM/PM
  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Adding 1 to month as it is zero-based
    const day = String(dateTime.getDate()).padStart(2, '0');
    const year = dateTime.getFullYear();
    let hours = dateTime.getHours();
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return `${month}/${day}/${year} ${formattedTime}`;
  };


  const quoteDetail = useMemo(() => { // Memoized function to calculate and return quote details
    const responseList = request.response; // Extracting response list from request object
    let quotedBy; // Variable to store the name of the user who quoted
    let quotedAt; // Variable to store the timestamp when the quote was modified/created

    // If responseList is empty or null, return null
    if (!responseList || responseList.length === 0) {
      return null;
    }

    // Checking if the first item in the responseList has a modified_at property
    if (responseList[0].modified_at) {
      quotedBy = responseList[0].modified_by.name; // If modified_at exists, use modified_by's name
      quotedAt = responseList[0].modified_at; // Store the modified timestamp
    } else {
      quotedBy = responseList[0].created_by.name; // If modified_at doesn't exist, use created_by's name
      quotedAt = responseList[0].created_at; // Store the created timestamp
    }

    let tempQuote = []; // Temporary array to store processed quote objects

    // Loop through each object in responseList
    for (let objRes of responseList) {
      tempQuote.push({
        id: objRes.id, // Add quote ID to tempQuote object
        quotation: objRes.quotation, // Add quote content to tempQuote object
        hours: objRes.hours, // Add hours to tempQuote object
      });
    }

    // Sort tempQuote array based on hours
    tempQuote.sort((a, b) => a.hours - b.hours);

    // Return an object containing quote details
    return {
      quoteStatus: !tempQuote[0].quotation ? 'not_interested_available' : 'submitted', // Determine quote status
      quotes: tempQuote, // Sorted list of quotes
      quoteBy: quotedBy, // Name of the user who quoted
      quoteAt: formatDate(quotedAt), // Formatted timestamp when the quote was created/modified
    };
  }, [request]); // Dependencies for useMemo


  const vehicleDetail = useMemo(() => { // Memoized function to calculate and return vehicle details
    const vehicle = request.vehicles; // Extracting vehicle details from request object
    
    if (!vehicle) { // If vehicle details are not available, return null
      return null;
    }
    
    let tempVehicle = { // Creating a temporary object to store processed vehicle details
      category: vehicle.category, // Adding vehicle category
      color: vehicle.color, // Adding vehicle color
      seat_fabric: VehicleSeat[vehicle.seat_fabric], // Adding seat fabric type
      passengers_count: vehicle.passengers_count, // Adding passengers count
      category_name: VehicleCategorySingularName[vehicle.category], // Adding singular category name
      color_code: vehicle.color, // Adding color code from predefined ColorCode object
      border_color_code: vehicle.color === 'black' ? 'vehicle-color-non-black-border' :'vehicle-color-black-border', // Adding border color code based on vehicle color
      color_name: VehicleColor[vehicle.color], // Adding color name from predefined VehicleColorLabel object
    };
    return tempVehicle; // Returning the processed vehicle details
  }, [request]); // Dependency for useMemo


  const openQuoteModel = () => {
    setIsModalOpen(true);
  }

  const sendResponse = (data) => {
    // if response available, update the response else create new response
    const isExistingQuote = data.filter((d)=> d.response_id && d.response_id !== 0);
    if(isExistingQuote.length > 0){
      UpdateLeadResponse(lead.id, data).then((d) => {
        if (d.code === 200) {
            messageApi.open({
                key,
                type: 'success',
                content: d.message,
            });
        }
      }).catch((e) => {
          messageApi.open({
              key,
              type: 'error',
              content: e.message,
          });
      });
    } else {
      SetLeadResponse(lead.id, data).then((d) => {
        if (d.code === 200) {
            messageApi.open({
                key,
                type: 'success',
                content: d.message,
            });
        }
      }).catch((e) => {
          messageApi.open({
              key,
              type: 'error',
              content: e.message,
          });
      });
    }
  }

  const _setIsModalOpen = (value) => {
    setIsModalOpen(value);
    setTimeout(() => {
      window.location.reload(); // to update the page after closing the modal
    }, 500);
  }

  return (
    <div className="vehicle-quote-container" onClick={() => openQuoteModel()}>
      {contextHolder}
      <div className="vehicle-info-container">
        <div className="vehicle-category-container">
          <span className="vehicle-category-text">{vehicleDetail?.category_name}</span>
          {request.is_hired && (
            <div className="hired-vehicle-ic-container">
              <HiredCheck />
            </div>
          )}
        </div>
        <div className="vehicle-spec-container">
          <div className="vehicle-single-spec">
            <PassengerCount />
            <span className="spec-text">{vehicleDetail.passengers_count}</span>
          </div>
          {vehicleDetail.seat_fabric && (
            <div className="vehicle-single-spec">
              <Seat />
              <span className="spec-text">{vehicleDetail.seat_fabric}</span>
            </div>
          )}
          <div className="vehicle-single-spec">
            {/* Setting color and background color for the quote */}
            <div className={`spec-color vehicle-color-list-${VehicleColor[vehicleDetail.color_code]} ${vehicleDetail.border_color_code}`}>
              {vehicleDetail.color === 'other' && (
                <span className="other-color-text">Other</span>
              )}
            </div>
            <span className="spec-text">{vehicleDetail.color_name}</span>
          </div>
        </div>
        <div className="quote-section">
          {!quoteDetail && (
            <div className="quote-status-container">
              <span className="quote-status-text">Not submitted</span>
              {canModify && (
                <Button size='small' type='primary' onClick={() => openQuoteModel()}>Quote Price</Button>
                // <div onClick={() => console.log("::::: Quote price", lead?.id)} className="quote-price-btn">
                //   <span style={{ color: 'white' }}>Quote price</span>
                // </div>
              )}
            </div>
          )}
          {quoteDetail && quoteDetail.quoteStatus === 'submitted' && (
            <div>
              <div className="quote-price-container">
                {quoteDetail.quotes.map((quoteItem, index) => (
                  <div className="single-quote-price-box" key={'quote' + index}>
                    <span className="quote-price-text">
                      {showQuoteLabel(quoteItem)} : ${quoteItem.quotation}
                    </span>
                  </div>
                ))}
              </div>
              <div className="quote-by-container">
                <span className="quote-by-text">By: {quoteDetail.quoteBy}</span>
                <div className="quote-time">
                  <ThinTime />
                  <span className="quote-by-text">{quoteDetail.quoteAt}</span>
                </div>
              </div>
            </div>
          )}
          {quoteDetail && quoteDetail.quoteStatus === 'not_interested_available' && (
            <div className="quote-status-container">
              <span className="quote-status-text" style={{ color: '#AAAAAA' }}>
                Not interested/available
              </span>
              {canModify && (
                <Button size='small' type='primary' onClick={() => openQuoteModel(index)}>Quote Price</Button>
                // <div onClick={() => console.log("::::: Quote price", lead?.id)} className="quote-price-btn">
                //   <span style={{ color: 'white' }}>Quote price</span>
                // </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isModalOpen && <QuotePrice
        req={[request]} 
        // since there's only one request object passing it as array & index as 0 for reusability
        vehicleIndex={0}
        isModalOpen={isModalOpen}
        sendResponse={sendResponse}
        otherMatchingVehicles={otherMatchingVehicles}
        tripType={lead.trip_type}
        roundTripData={roundTripData}
        setIsModalOpen={_setIsModalOpen} />}
    </div>
  );
};

export default VehicleQuoteComponent;
