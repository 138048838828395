import React from 'react';
import LeadAddress from '../LeadComponents/lead_address';
import LeadDateTime from '../LeadComponents/lead_date_time';
import TripDetails from '../LeadComponents/trip_details';
import { getLeadAddressStr, GetRoundTripData } from '../../services/helper';
import VehicleQuoteComponent from '../LeadComponents/vehicle_quote_component';
import { LeadQuoteStatus } from '../../constants';

const SentQuoteCard = ({ leadData, onClick }) => {

    const getFormattedRequest = (request, lead) => {
        const vehicles = {
            id: request.vehicle.id,
            category: request.vehicle.category,
            color: request.vehicle.color,
            seat_fabric: request.vehicle.seat_fabric,
            passengers_count: request.vehicle.passengers_count,
        };

        const response = lead.responses.filter((item) => item.request_id === request.id);
        return { id: request.id, lead_id: lead.id, is_hired: request.is_hired, hours: request.hours, vehicles, response };
    };

    const otherMatchingVehicles = (request) => {
        return request.filter((item) => item.vehicle.id !== null).map((i)=> i.vehicle.id);
    }

    return (
        <div className='lead-card sent-quote-card' >
            <div onClick={() => onClick(leadData.id)}>
                {/* Address card component */}
                <LeadAddress
                    pickup={getLeadAddressStr(leadData.pickup_city, leadData.pickup_state)}
                    dropOff={getLeadAddressStr(leadData.dropoff_city, leadData.dropoff_state)}
                    vehicleLength={leadData.request.length}
                    showMatchingVehicleBadge={false}
                    status={leadData.status}
                />

                {/* Lead Time and Date Component */}
                <LeadDateTime
                    date={leadData.pickup_date}
                    time={leadData.pickup_time}
                    hrs={leadData.trip_hours}
                    tripType={leadData.trip_type}
                />
                {/* Trip Details */}
                <TripDetails tripDetail={leadData.trip_details || ""} />
            </div>
            {leadData.request.map((reqItem, index) => {
                return <VehicleQuoteComponent
                    key={`vehicle-quote-${reqItem.id}`}
                    canModify={!(leadData.status === LeadQuoteStatus.Hired || leadData.status === LeadQuoteStatus.Closed)}
                    fromSentQuote={true}
                    index={reqItem.id}
                    roundTripData={GetRoundTripData(leadData)}
                    otherMatchingVehicles={otherMatchingVehicles(leadData.request)}
                    lead={{ id: leadData.id, trip_hours: leadData.trip_hours, trip_type: leadData.trip_type }}
                    request={getFormattedRequest(reqItem, leadData)}
                    addQuote={(reqIndex) => {
                        // console.log(reqIndex)
                    }} />
            })}

        </div>
    )
}

export default SentQuoteCard;