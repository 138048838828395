// Import Module
import React, { useState } from 'react';
import { Button, message} from 'antd';
import { DeleteAffiliate } from '../services/api';
import { GetCookie } from '../services/helper';

const AccountSettings = () => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';
  const id = GetCookie('AffiliateID');

  return (
    <div className='text-center'>
      {contextHolder}
      <Button danger type="primary" 
        className='mt-20'
        loading={loading}
        onClick={() => {
          setLoading(true);
          DeleteAffiliate(id).then((d) => {
            if (d.code === 200) {
              messageApi.open({
                key,
                type: 'success',
                content: 'Account deleted successfully',
              });
            }
            setLoading(false);
          }).catch((e) => {
            messageApi.open({
              key,
              type: 'error',
              content: e.message,
            });
            setLoading(false);
          });
        }}>
        Delete Account
      </Button>
    </div >
  );
};

export default AccountSettings;
