import dayjs from 'dayjs';
import 'dayjs/plugin/relativeTime';

import { TripType } from '../constants';

export const GetCookie = (cName) => {
  const name = `${cName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const ValidateEmail = (email) => {
  const _emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/;
  // const _emailRegex =
  // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return _emailRegex.test(email.trim());
};

export const ValidateURL = (url) => {
  // Regular expression for a valid URL format
  const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

  // Test the URL against the regex
  return urlRegex.test(url);
};

export const ValidatePhone = (phone) => {
  // Remove the +1 prefix
  phone = phone.replace('+1 ', '');

  if(phone === '000-000-0000' || phone === '0000000000' || 
    phone === '111-111-1111' || phone === '1111111111') {
    return false;
  }
  phone = phone.replaceAll('-', '');
  // Regular expression for a valid Phone format
  const phoneRegex = /^[2-9]\d{9}$/;

  // Test the URL against the regex
  return phoneRegex.test(phone);
};

export const FormatPhone = (phone) => {
  phone = phone.replace('+1 ', '');
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const ValidateZIP = (url) => {
  // Regular expression for a valid ZIP format
  const urlRegex = /^\d{5}?$/;

  // Test the URL against the regex
  return urlRegex.test(url);
};


export const SetCookie = (name, value, days = 30) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const RemoveCookie = function (name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

// export const GetTagColor = (tag) => {
//   switch (tag) {
//     case Status.active:
//       return 'success';

//     case Status.available:
//       return 'success';

//     case Status.pending:
//       return 'processing';

//     case Status.rejected:
//       return 'red';

//     case Status.approved:
//       return 'success';

//     case Status.unread:
//       return 'yellow';

//     case Status.open:
//       return 'processing';

//     case Status.sent_for_quote:
//       return 'orange';

//     case Status.quote_received:
//       return 'blue';

//     case Status.hired:
//       return 'green';

//     case Status.archived:
//       return 'red';

//     case Status.incomplete:
//       return 'magenta';

//     case Status.low_budget:
//       return 'volcano';

//     case Status.deleted:
//       return 'red';

//     case Status.sent_to_agent:
//       return 'yellow';

//     default:
//       return 'default';
//   }
// };

export const GetEnumKey = (data, value) => {
  const keys = Object.keys(data);
  return keys.find((key) => data[key] === value);
};

export const GetOptionForEnum = (data) => {
  return Object.keys(data).map(key => ({
    text: data[key],
    value: key
  }));
};


export const GetOptionForOnlyValue = (data) => {
  return Object.keys(data).map(key => ({
    // text: data[key],
    value: data[key]
  }));
};

export const formatAMPM = (date) => {
  let minutes = date.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes
  return `${(date.getHours() % 12) || 12}:${minutes} ${date.getHours() < 12 ? 'AM' : 'PM'}`
}

export const FormatDate = (timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  // const hours = date.getHours().toString().padStart(2, '0');
  // const minutes = date.getMinutes().toString().padStart(2, '0');
  const AMPMTime = formatAMPM(date);
  return `${month}-${day}-${year} ${AMPMTime}`;
}

export const FormatDateWithoutTime = (timestamp) => {
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

export const ConvertTo12HourFormat = (timeString) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  let hours12 = hours % 12;
  hours12 = hours12 || 12; // 12:00 should remain 12, not 0

  // Format hours and minutes with leading zeros if necessary
  const hoursStr = hours12; // String(hours12).padStart(2, '0');
  const minutesStr = String(minutes).padStart(2, '0');

  // Concatenate hours, minutes, and period
  return `${hoursStr}:${minutesStr} ${period}`;
};

// export const ToggleZoom = () => {
//   const zoomLevel = GetCookie('Zoom');
//   const isSentToAgent = (window.location.pathname === '/sent-to-agent')
//   if (!zoomLevel || zoomLevel === '100') {
//     if(isSentToAgent){
//       SetCookie('Zoom', '75');
//     } else {
//       SetCookie('Zoom', '80');      
//     }
//   } else {
//     RemoveCookie('Zoom');
//   }
//   SetBrowserZoom();
// };

// export const SetBrowserZoom = () => {
//   const zoomLevel = GetCookie('Zoom');
//   if (zoomLevel && zoomLevel !== '100') {
//     const isSentToAgent = (window.location.pathname === '/sent-to-agent')
//     if(isSentToAgent){
//       document.body.classList.remove("zoom-in");
//       document.body.classList.add("zoom-in-2");
//     } else {
//       document.body.classList.add("zoom-in");
//       document.body.classList.remove("zoom-in-2");
//     }
//   } else {
//     document.body.classList.remove("zoom-in");
//     document.body.classList.remove("zoom-in-2");
//   }
// }

export const FormatDateWithDay = (inputDate) => {
  // Parse input date
  const [month, day, year] = inputDate.split('-').map(Number);

  // Create a new Date object
  const dateObj = new Date(year, month - 1, day);

  // Array of month names
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  // Array of day names
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // Get day, month name, day of month, and year
  const dayName = dayNames[dateObj.getDay()];
  const monthName = monthNames[dateObj.getMonth()];
  const dayOfMonth = dateObj.getDate();
  const formattedYear = dateObj.getFullYear();

  // Construct formatted date string
  const formattedDate = `${dayName} ${monthName} ${dayOfMonth}, ${formattedYear}`;

  return formattedDate;
}


// Function to format phone number for sending to server (add country code)
export const FormatPhoneNumberForServer = (phoneNumber) => {
  // Assuming phone number format: XXX-XXX-XXXX
  return (phoneNumber.startsWith('+1')) ? phoneNumber : `+1 ${phoneNumber}`;
};

export const IsStrongPassword = (password) => {
  if (password.includes(' ')){
    return false;
  }
  // Regular expression to check for the specified criteria
  const passwordRegex =
    /^(?!.* ).{8,}$/;
    

  // Test the password against the regex
  return passwordRegex.test(password);
};

export const FormatTripHours = (hours) => {
  let value = '';
  switch (hours) {
    case 'one_way_transfer':
      value = "One Way";
      break;
    case 'multiple_day_usage':
      value = "Multi Days";
      break;
    case 'trip_1':
      value = "Trip 1";
      break;
    case 'trip_2':
      value = "Trip 2";
      break;
      case '1':
        value = `${hours} Hr`; // to remove S from 1 Hr
        break;
    // In old build it's received as null so to handle it add null case
    case null:
      value = 'One Way/ Multi';
      break;
    default: 
      value = `${hours} Hrs`
  }
  return value;
}

// export const GetTimeDifference = (timestamp) => {
//   dayjs.extend(require('dayjs/plugin/relativeTime'));
//   const date = dayjs(timestamp);
//   const current = dayjs();

//   const daysDifference = current.diff(date, 'days');

//   if (daysDifference <= 7) {
//       return date.fromNow({ includeSeconds: true, suffix: true }) + ' ago';
//   } else {
//       return date.format('MM-DD-YYYY');
//   }
// };

// export const GetTimeDifferenceViaDateTime = (dateString, timeString) => {
//   // Parse the date and time strings using Day.js
//   var customDateTime = dayjs(dateString, 'MM-DD-YYYY');
//   // var customDateTime = dayjs(dateString + ' ' + timeString, 'MM-DD-YYYY HH:mm A');
//   return GetTimeDifferenceInHours(customDateTime);
// }

// export const GetTimeDifferenceInHours = (timestamp) => {
//   const date = dayjs(timestamp);
//   const current = dayjs();

//   const hoursDifference = current.diff(date, 'hours');
//   return hoursDifference + 1; // Add 1 to make it round off to next hour
// };

// export const GetTimeDifferenceInDays = (timestamp) => {
//   const date = dayjs(timestamp);
//   const current = dayjs();

//   const daysDifference = date.diff(current, 'days');
//   return daysDifference + 1; // Add 1 to make it round off to next day
// };

// Function to format phone number for display (remove country code)
export const FormatPhoneNumberForDisplay = (phoneNumber) => {
  // Assuming phone number format: +1-XXX-XXX-XXXX
  return phoneNumber? phoneNumber.replace('+1 ', '') : '';
};

export const GetClickableLink = link => {
  return link.startsWith("http://") || link.startsWith("https://") ?
    link
    : `http://${link}`;
};

export const getLeadAddressStr = (pickUpCity, pickUpState) => {
  return (pickUpCity || '') + (pickUpCity && pickUpState ? ', ' : '') + (pickUpState || '');
};


export const getLeadDateStr = (dateStr) => {
  const dateSplit = dateStr ? dateStr.split('-') : [];
  const formattedDate =
    dateSplit.length === 3 ? dateSplit[1] + '/' + dateSplit[2] + '/' + dateSplit[0] : '';
  return formattedDate;
};

export const getLeadTimeStr = (timeString) => {
  if (!timeString) {
    return '00:00 AM';
  }
  var timeParts = timeString.split(':');
  var hours = parseInt(timeParts[0], 10);
  var minutes = parseInt(timeParts[1], 10);

  var period = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12) {
    hours -= 12;
  }

  var formattedTime =
    hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + period;
  return formattedTime;
};  

export const getLeadHoursStr = (hours, type) => {
  if (type !== TripType.fixed_hours) {
    return TripType[type];
  }
  if (hours !== 0) {
    return hours + ' hrs';
  }
  return hours + ' hrs';
};

export const getBrowserDetails = () => {
  const nAgt = navigator.userAgent;
  let browserName  = navigator.appName;
  let fullVersion  = ''+parseFloat(navigator.appVersion); 
  let nameOffset,verOffset,ix;

  // In Opera, the true version is after "OPR" or after "Version"
  if ((verOffset=nAgt.indexOf("OPR"))!==-1) {
  browserName = "Opera";
  fullVersion = nAgt.substring(verOffset+4);
  if ((verOffset=nAgt.indexOf("Version"))!==-1) 
    fullVersion = nAgt.substring(verOffset+8);
  }
  // In MS Edge, the true version is after "Edg" in userAgent
  else if ((verOffset=nAgt.indexOf("Edg"))!==-1) {
  browserName = "Microsoft Edge";
  fullVersion = nAgt.substring(verOffset+4);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
  browserName = "Microsoft Internet Explorer";
  fullVersion = nAgt.substring(verOffset+5);
  }
  // In Chrome, the true version is after "Chrome" 
  else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
  browserName = "Chrome";
  fullVersion = nAgt.substring(verOffset+7);
  }
  // In Safari, the true version is after "Safari" or after "Version" 
  else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
  browserName = "Safari";
  fullVersion = nAgt.substring(verOffset+7);
  if ((verOffset=nAgt.indexOf("Version"))!==-1) 
    fullVersion = nAgt.substring(verOffset+8);
  }
  // In Firefox, the true version is after "Firefox" 
  else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
  browserName = "Firefox";
  fullVersion = nAgt.substring(verOffset+8);
  }
  // In most other browsers, "name/version" is at the end of userAgent 
  else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
            (verOffset=nAgt.lastIndexOf('/')) ) 
  {
  browserName = nAgt.substring(nameOffset,verOffset);
  fullVersion = nAgt.substring(verOffset+1);
  if (browserName.toLowerCase() === browserName.toUpperCase()) {
    browserName = navigator.appName;
  }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix=fullVersion.indexOf(";"))!==-1)
    fullVersion=fullVersion.substring(0,ix);
  if ((ix=fullVersion.indexOf(" "))!==-1)
    fullVersion=fullVersion.substring(0,ix);

  return {
    browserName: browserName,
    version: fullVersion,
    userAgent: navigator.userAgent,
  }
}

export const GetRoundTripData = (lead) =>{
  let roundTripData = null;
  if(lead.trip_type === GetEnumKey(TripType, TripType.round_trip)) {
    roundTripData = {
      pickup_city: lead.pickup_city,
      dropoff_city: lead.dropoff_city,
      pickup_date: dayjs(lead.pickup_date).format('DD MMMM'),
      return_pickup_date: dayjs(lead.return_pickup_date).format('DD MMMM'),
      pickup_time: getLeadTimeStr(lead.pickup_time),
      return_pickup_time: getLeadTimeStr(lead.return_pickup_time),
    };
  }
  return roundTripData;
}