// Import Module
import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Col, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ResetPwd } from '../services/api';
import { GetCookie, IsStrongPassword } from '../services/helper';

import SideCarousel from '../components/side_carousel';
import logo from '../assets/images/price4limo-logo.jpg';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const key = 'updatable';
  const isAffiliate = window.location.pathname.includes("/affiliate/reset-password-web/")  ? true : false;

  useEffect(() => {
    const accessToken = GetCookie('AffiliateAccessToken');
    if (accessToken) {
      navigate('/leads');
    }
  }, [navigate]);

  const onSetPassword = () => {
    if (!IsStrongPassword(password)) {
      messageApi.open({
        key,
        type: 'error',
        content:
          'Password should be minimum of 8 characters and cannot contain whitespace.',
      });
    } else if (password !== cPassword) {
      messageApi.open({
        key,
        type: 'error',
        content: "Passwords don't match.",
      });
    } else {
      setLoading(true);
      // make API call
      const data = {
        reset_token: token,
        password: password,
      };
      ResetPwd(data)
        .then((d) => {
          setLoading(false);
          messageApi.open({
            key,
            type: 'success',
            content: 'Your password has been successfully reset.',
            duration: 2,
          });

          setTimeout(() => {
            navigate('/login');
          }, 1000);
        })
        .catch((e) => {
          setLoading(false);
          messageApi.open({
            key,
            type: 'error',
            content: e.message,
          });
        });
    }
  };

  return (
    <div className='auth-wrapper'>
      <SideCarousel />
      {contextHolder}
      <Col className='auth-section'>
        <Form>
          <div className='login-logo-wrapper'>
            <img src={logo} alt='price 4 limo' className='login-logo' />
          </div>
          <h1>Create new password</h1>
          <p className='text-center'>Password must be at least 8 digits. 
            <br />
            Your login email address is: {email}</p>
          <div className='mb-3'>
            <label>Password</label>
            <Input.Password
              placeholder='Enter new password'
              size='large'
              maxLength={48}
              value={password}
              prefix={<LockOutlined className='site-form-item-icon' />}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='mb-3'>
            <label>Re-type Password</label>
            <Input.Password
              maxLength={48}
              placeholder='Re-type password'
              size='large'
              className='form-control'
              value={cPassword}
              onChange={(e) => setCPassword(e.target.value)}
              prefix={<LockOutlined className='site-form-item-icon' />}
            />
          </div>
          <Button
            type='primary'
            block={true}
            className='btn btn-primary'
            size='large'
            loading={loading}
            onClick={onSetPassword}
          >
            Set Password
          </Button>
          {/* If it's a affiliate reset password do not show back to login button */}
          {!isAffiliate && <p className='back-to-login'>
            Back to <a className='ant-btn-link' href='/login'>Login</a>
          </p>}
        </Form>
      </Col>
    </div>
  );
};

export default ResetPassword;
