// Import Modules
import axios from 'axios';
import { GetCookie, RemoveCookie } from './helper';
const CookieName = 'LimoPriceToken';

let funcToResolve = [];
let isLoading;

export const makeHTTPCall = (
  method,
  url,
  headers,
  params = null,
  controller = null,
  timeout = null
) =>
  new Promise((resolve, reject) => {
    axios({
      method,
      url,
      headers,
      timeout,
      // Abort controller
      // signal: controller?.signal,
      cancelToken: controller?.token,
      data: params,
      responseType: 'json',
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        if (error.response) {
          const {
            response: { status, data },
          } = error;
          // If token is expired or unauthorized then user has to login again
          if (status === 401) {
            //Logout user

            RemoveCookie('AffiliateAccessToken');
            RemoveCookie('AffiliateRefreshToken');
            RemoveCookie('AffiliateUser');
            window.location = '/login';

            // If Multiple 401 Error is Called at Once then Push all the API calls Parameters in an array, Generate the Token Once ( Not Multiple for each API Call ),
            // If new token is Generated then make all the Rest API Calls According to the Function
            // customResolve while hold information about which request is to be resolved
            // in case of multiple request failed
            funcToResolve.push({
              reqMethod: method,
              reqUrl: url,
              reqHeaders: headers,
              reqParams: params,
              customResolve: resolve,
            });

            if (!isLoading) {
              isLoading = true;
              // TokenRefreshData.refreshToken = GetCookie(CookieName.refreshToken);
              // TokenRefreshData.revokeToken = GetCookie(CookieName.accessToken);
              // TokenRefreshData.sessionId = GetCookie(CookieName.sessionId);

              // GenerateNewToken(TokenRefreshData).then(() => { // If new token is Generated then make all the Rest API Calls According to the Function
              //     isLoading = false;
              //     for (let i = 0; i < funcToResolve.length; i++) {
              //         const {
              //             reqMethod, reqUrl, reqHeaders, reqParams, customResolve,
              //         } = funcToResolve[i];
              //         const accessToken = GetCookie(CookieName.accessToken);
              //         reqHeaders[Constants.HttpRequest.Headers.Keys.AUTHORIZATION] = BearerToken(accessToken);
              //         customResolve(makeHTTPCall(reqMethod, reqUrl, reqHeaders, reqParams));
              //     }
              //     funcToResolve = [];
              // });
            }
          } else {
            reject(data);
          }
        } else {
          // console.log('Error', error);
        }
      });
  });

export const GET = (url, headers, controller = null) =>
  new Promise((resolve) => {
    if (!headers) {
      // Set Default Headers
      headers = {};
      const accessToken = GetCookie(CookieName);
      headers['Authorization'] = accessToken;
    }
    resolve(makeHTTPCall('get', url, headers, null, controller));
  });

export const PUT = (url, headers, params) =>
  new Promise((resolve) => {
    if (!headers) {
      // Set Default Headers
      headers = {};
      const accessToken = GetCookie(CookieName);
      headers['Authorization'] = accessToken;
    }
    resolve(makeHTTPCall('put', url, headers, params));
  });

export const PATCH = (url, headers, params) =>
  new Promise((resolve) => {
    if (!headers) {
      // Set Default Headers
      headers = {};
      const accessToken = GetCookie(CookieName);
      headers['Authorization'] = accessToken;
    }
    resolve(makeHTTPCall('patch', url, headers, params));
  });

export const POST = (url, headers, params) =>
  new Promise((resolve) => {
    if (!headers) {
      // Set Default Headers
      headers = {};
      const accessToken = GetCookie(CookieName);
      headers['Authorization'] = accessToken;
    }
    resolve(makeHTTPCall('post', url, headers, params));
  });

export const DELETE = (url, headers, params) =>
  new Promise((resolve) => {
    if (!headers) {
      // Set Default Headers
      headers = {};
      const accessToken = GetCookie(CookieName);
      headers['Authorization'] = accessToken;
    }
    resolve(makeHTTPCall('delete', url, headers, params));
  });
