// Import Module
import React, { useEffect, useState } from "react";
import { Modal, Select, Button, InputNumber, Radio } from "antd";
import { GetEnumKey, GetOptionForEnum } from "../services/helper";
import { VehicleCategorySingularName, VehicleColor, VehicleSeat, VehiclePassengerCapacity, VehicleWithSeatFabric } from "../constants";

const VehicleInfoModal = ({
  isModalOpen,
  setIsModalOpen,
  addVehicleIntoList,
  editModeIndex,
  messageApi,
  vehicle,
  loading,
}) => {
  const [vehicleType, setVehicleType] = useState(vehicle?.category);
  const [passengerCount, setPassengerCount] = useState(vehicle?.passenger_count);
  const [color, setColor] = useState(vehicle?.color || "");
  const [seat, setSeat] = useState(vehicle?.seat_fabric || "");
  const key = "updatable";

  useEffect(() => {
    setVehicleType(vehicle?.category);
    setPassengerCount(vehicle?.passenger_count);
    setColor(vehicle?.color);
    setSeat(vehicle?.seat_fabric);
  }, [vehicle]);

  const addVehicle = () => {
    // validate vehicle
    if (!vehicleType || !passengerCount || !color || (!seat && VehicleWithSeatFabric.includes(vehicleType))) {
      messageApi.open({
        key,
        type: "error",
        content: `Please fill all the fields.`,
      });
      return false;
    } else {
      let obj = {
        category: vehicleType,
        passenger_count: passengerCount,
        color: color,
        seat_fabric: VehicleWithSeatFabric.includes(vehicleType) ? seat : null,
        status: "available",
      };
      if (editModeIndex > -1) {
        obj.id = vehicle.id; // need Id for edit mode
        obj.key = vehicle.key; // need key for edit mode
        obj.index = editModeIndex; // need index for edit mode
      }
      addVehicleIntoList(obj);
    }
  };

  return (
    <Modal
      title="Select your vehicle information"
      open={isModalOpen}
      centered
      width={600}
      // okText={'Yes'} cancelText={'No'} onOk={statusUpdate}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            addVehicle();
          }}
        >
          {editModeIndex > -1 ? "Update Vehicle" : "Add Vehicle"}
        </Button>,
      ]}
    >
      <div>
        
        <div className="flex-separate">
          <div className="add-affiliate-form-item-label flex-1">
            Select a vehicle
          </div>
          <Select
            value={VehicleCategorySingularName[vehicleType]}
            placeholder="Select vehicle"
            onChange={(v, o) => {
              const _vehicleType = GetEnumKey(VehicleCategorySingularName, v);
              setVehicleType(_vehicleType);
              if (!VehicleWithSeatFabric.includes(_vehicleType)) {
                setSeat(null);
              }
              setPassengerCount(VehiclePassengerCapacity[_vehicleType]);
            }}
            className="vehicle-select-list-container flex-2"
            options={GetOptionForEnum(VehicleCategorySingularName).map((category) => {
              return {
                value: category.text,
                className: "vehicle-select-list-item",
                label: (
                  <>
                    <span
                      className={` ${category.value} vehicle-select-list`}
                    ></span>
                    <span>{category.text}</span>
                  </>
                ),
              };
            })}
          />
        </div>
        <div className="flex-separate mt-10">
          <div className="add-affiliate-form-item-label flex-1">Passengers</div>
          <InputNumber
            min={1}
            max={999}
            placeholder="Passengers"
            className="vehicle-passenger-list-input flex-2"
            maxLength={3}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={passengerCount}
            onChange={(v) => setPassengerCount(v)}
          />
        </div>
        {VehicleWithSeatFabric.includes(vehicleType) && <div className="flex-separate mt-10">
          <div className="add-affiliate-form-item-label flex-1">Seat type</div>
          <Radio.Group
            onChange={(e) => setSeat(e.target.value)}
            value={seat}
            className="flex-2"
          >
            {GetOptionForEnum(VehicleSeat).map((category) => {
              return (
                <Radio key={`seat-${category.value}`} value={category.value}>
                  {category.text}
                </Radio>
              );
            })}
          </Radio.Group>
        </div>}
        <div className="flex-separate mt-10">
          <div className="add-affiliate-form-item-label flex-1">
            Select a vehicle color
          </div>
          <Select
            value={VehicleColor[color]}
            placeholder="Select vehicle color"
            onChange={(v, o) => setColor(GetEnumKey(VehicleColor, v))}
            className="vehicle-color-list-container flex-2"
            options={GetOptionForEnum(VehicleColor).map((category) => {
              return {
                value: category.text,
                className: "vehicle-color-list-item",
                label: <div className="flex-separate jc-start">
                  <div className={`vehicle-color-list-icon vehicle-color-list-${category.text}`}></div>
                  <div>{category.text}</div>
                </div>,
              };
            })}
          />
        </div>
      </div>
    </Modal>
  );
};

export default VehicleInfoModal;
