import React from 'react'

const HiredCheck = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0C3.13403 0 0 3.13403 0 7C0 10.8662 3.13403 14 7 14C10.8662 14 14 10.8662 14 7C14 3.13403 10.8662 0 7 0ZM7 13.1388C3.62272 13.1388 0.875 10.3773 0.875 6.99997C0.875 3.62269 3.62272 0.874973 7 0.874973C10.3773 0.874973 13.125 3.6227 13.125 6.99997C13.125 10.3772 10.3773 13.1388 7 13.1388ZM9.79366 4.43866L5.68661 8.5715L3.83708 6.72197C3.66624 6.55113 3.3893 6.55113 3.21824 6.72197C3.04739 6.89281 3.04739 7.16975 3.21824 7.34059L5.38364 9.50622C5.55449 9.67684 5.83142 9.67684 6.00249 9.50622C6.02217 9.48653 6.03903 9.46508 6.05434 9.44278L10.4127 5.05749C10.5833 4.88664 10.5833 4.6097 10.4127 4.43866C10.2417 4.26781 9.96472 4.26781 9.79366 4.43866Z" fill="white" />
        </svg>
    )
}

export default HiredCheck