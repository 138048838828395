// Import Module
import React, {useState} from "react";
import { Button, Alert, Space, Input, message } from 'antd';

import { GetCookie, FormatPhoneNumberForServer, ValidateEmail, ValidatePhone } from "../services/helper";
import PhoneInput from './phone_input';
import { DeleteOutlined } from '@ant-design/icons';
import { EditContact, CreateContact, DeleteContact } from '../services/api';

const Contact = ({ saveContact, contactData, isPartialForm }) => {
  const [contacts, setContacts] = useState(contactData?.length > 0 ? contactData :
    [{
      name: '',
      email: '',
      phone_number: '',
      is_primary: true,
    }]);
  const [messageApi, contextHolder] = message.useMessage();
  const affiliateId = GetCookie('AffiliateID');
  const key = '_updatable';

  const addContact = () => {
    let _data = [...contacts];
    _data.push({
      name: '',
      email: '',
      phone_number: '',
      is_primary: false,
    });
    setContacts(_data);
    saveContact(_data);
  }

  const deleteContact = (index) => {
    let _data = [...contacts];
    if(isPartialForm) {
      if(_data[index].id) { // Call API to delete contact only if contact is saved in DB
        DeleteContact(affiliateId, _data[index].id).then((d) => {
          if (d.code === 200) {
            _data.splice(index, 1);
            setContacts(_data);
            saveContact(_data);
            message.success('Contact removed successfully!');
          }
        }).catch((e) => {
          console.log('e');
        });
      }  else {
        // just remove contact from state
        _data.splice(index, 1);
        setContacts(_data);
        saveContact(_data);
      }
    } else {
      // just remove contact from state
      _data.splice(index, 1);
      setContacts(_data);
      saveContact(_data);
    }
  }

  const updateData = (index, key, value) => {
    let _data = [...contacts];
    let obj = _data[index];
    if(key === 'email') {
      obj[key] = value.trim(); // to remove white spaces
    } else {
      obj[key] = value;
    }
    setContacts(_data);
    saveContact(_data);
    
  };

  const validate = (contact) =>{
    if (!contact.name) {
      messageApi.open({
        key,
        type: 'error',
        content: `Name is required!`,
      });
      return false;
    } else if (!contact.email) {
      messageApi.open({
        key,
        type: 'error',
        content: `Email address is required!`,
      });
      return false;
    } else if (!ValidateEmail(contact.email) || contact.email.includes(' ')) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please enter a valid email address.`,
      });
      return false;
    } else if (!contact.phone_number) {
     messageApi.open({
       key,
       type: 'error',
       content: `Mobile number is required!`,
     });
     return false;
   } else if (contact.phone_number.length < 10
       || !ValidatePhone(contact.phone_number)
    ) {
      messageApi.open({
        key,
        type: 'error',
        content: `Invalid mobile number!`,
      });
      return false;
    } else {
      return true;
    }
  }

  const saveContactApi = (index) => {
    if(isPartialForm) { // API Call only for partial form

      let _contacts = [...contacts];
      let _contact = _contacts[index];
      const isValid = validate(_contact);
      if(!isValid) return;
      // Call API to save or edit contact
      _contact.phone_number = FormatPhoneNumberForServer(_contact.phone_number);
      if(_contact.id){
        EditContact(affiliateId, _contact.id, _contact).then((d) => {
          if (d.code === 200) {
            messageApi.open({
              key,
              type: 'success',
              content: 'Contact saved successfully!',
            });
          }
        }).catch((e) => {
          console.log('e');
          messageApi.open({
            key,
            type: 'error',
            content: e.message,
          });
        });
      } else{
        CreateContact(affiliateId, _contact).then((d) => {
          if (d.code === 200) {
            messageApi.open({
              key,
              type: 'success',
              content: 'Contact created successfully!',
            });

            // Update contact id in state
            _contacts[index].id = d.data.id;
            setContacts(_contacts);
            saveContact(_contacts);
          }
        }).catch((e) => { 
          console.log('e'); 
          messageApi.open({
            key,
            type: 'error',
            content: e.message,
          });
        });
      } 
    }
  };

  const generateContactForm = () => {
    return contacts.map((contact, index) => {
      return (
        <div className='add-contact-affiliate-form' key={`aff-contact-${index}`}>
          <div className='flex-separate'>
            <h3>{(contact.is_primary) ? 'Primary Contact' : `Contact person ${index + 1}`}</h3>
            {index > 0 && <Button type="primary" danger icon={<DeleteOutlined />} size='small' onClick={() => { deleteContact(index) }}>Remove</Button>}
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Name</div>
            <Input value={contact.name} placeholder='Enter name' maxLength={35} onChange={(e) => updateData(index, 'name', e.target.value)} />
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Email address</div>
            <Input value={contact.email} 
              placeholder='Enter email address' 
              // maxLength={48} 
              onChange={(e) => updateData(index, 'email', e.target.value)} />
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Mobile number</div>
            <PhoneInput  value={contact?.phone_number} placeholder='Enter mobile number' onChange={(e) => updateData(index, 'phone_number', e)}/>
            {/* <Input addonBefore="+1" value={FormatPhoneNumberForDisplay(contact.phone_number)} maxLength={10} placeholder='Enter mobile' onChange={(e) => updateData(index, 'phone_number', e.target.value)} /> */}
          </div>

          {isPartialForm && <Button onClick={() => saveContactApi(index)}>Save</Button>}
          <hr />
        </div >
      )
    });
  }

  return (
    <div className='affiliate-contact-container'>
      {isPartialForm && contextHolder}
      <div>
        {generateContactForm()}
      </div>
      {contacts.length < 10 &&
        <Alert
          className='alert-section'
          message="Add extra contacts"
          description="Do you want to add additional team members to your company? Can add up to 2 additional contacts who will be able to view and respond to all incoming lead requests."
          type="info"
          action={
            <Space direction="vertical">
              <Button className='alert-section-btn' size="small" type="primary" onClick={addContact}>
                Add Contact
              </Button>
            </Space>
          }
        />
      }
    </div>
  )
};

export default Contact;
