// Import Module
import React, {useEffect, useState} from "react";
import { Button, Modal, Input, Typography, List, Checkbox, message } from 'antd';

import { FormatTripHours, GetCookie } from "../services/helper";
import {  } from '@ant-design/icons';
import { GetAffiliateVehicles, CreateRequest } from "../services/api";
import { VehicleCategorySingularName, VehicleColor, VehicleSeat } from "../constants";
import VehicleCheck from '../assets/images/success-check.png';

import PassengerCount from '../assets/images/svg/PassengerCount';
import Seat from '../assets/images/svg/Seat';

const { Text } = Typography;
const QuotePrice = ({ req, vehicleIndex, isModalOpen, setIsModalOpen, sendResponse, otherMatchingVehicles=[], tripType, roundTripData }) => {

  const [vhIndex, setVhIndex] = useState(vehicleIndex);
  const [request, setRequest] = useState(req);
  const [quotePerHour, setQuotePerHour] = useState({});
  const [isAllQuoteSubmitted, setIsAllQuoteSubmitted] = useState(false);
  const [isNMVLoading, setNMVLoading] = useState(false);
  const [isNMVCycleDone, setIsNMVCycleDone] = useState(false);
  const [affVehicles, setAffVehicles] = useState([]); // store aff vehicles to avoid flicker
  const [nonMatchingVehicles, setNonMatchingVehicles] = useState([]);
  const [apiCallCounter, setApiCallCounter] = useState(0);

  useEffect(() => {
    const activeResp = request[vhIndex].response;
    if (activeResp.length > 0) {
      let qph = {};
      activeResp.forEach((r) => {
        qph[r.hours] = r.quotation;
      });
      setQuotePerHour(qph);
    } else {
      setQuotePerHour({}); // reset the quote per hour
    }
  }, [request, vhIndex]); //eslint-disable-line

  useEffect(()=>{
    GetAffiliateVehicles(GetCookie('AffiliateID')).then((d) => {
      if (d.code === 200) {
        setAffVehicles(d.data);
      }
    });
  }, []);

  const setNotInterested = () => {
    // need to send the responseId and the data array object
    const activeRequest = request[vhIndex];
    let data = [];
    if(activeRequest.response.length > 0){
      activeRequest.response.forEach((r) => {
        data.push({
          'response_id': r.id,
          'hours': null,
          'quotation': null,
          'request_id': activeRequest.id,
          'is_not_available': true,
          'is_deleted': false,
        });
      });
    }
    else {
      data = [
          {
              'hours': null,
              'quotation': null,
              'request_id': request[vhIndex].id,
              'is_not_available': true,
              'is_deleted': false,
          }
      ];
    }
    sendResponse(data);
    roundRobin();
  };

  const submitAndNext = () => {
    // Quotation should be not null for at least single hour
    let isQuotationNull = Object.keys(quotePerHour)
      .filter((key) => quotePerHour[key] !== null && quotePerHour[key] === '');

    // check if quotation is 0 for any hour
    let isQuotationZero = Object.keys(quotePerHour).filter((key) => quotePerHour[key] === 0);
    if (isQuotationNull.length === Object.keys(quotePerHour).length) {
      message.error('Please enter quotation.');
      return; 
    }

    if(isQuotationZero.length > 0){
      message.error('Please enter valid quotation.');
      return;
    }

    // need to send the responseId and the data array object
    const activeRequest = request[vhIndex];
    let data =[];

    const aResp = activeRequest.response;
    // patch to delete the response if the quote is empty 
    // to remove unnecessary response
    const _toDelete = aResp.filter((r) => r.hours === null && r.quotation === null);
      
    _toDelete.forEach((r) => {
      data.push({
        'response_id': r.id,
        'hours': null,
        'quotation': null,
        'request_id': activeRequest.id,
        'is_not_available': false,
        'is_deleted': true,
      });
    });

    Object.keys(quotePerHour).forEach((key) => {
      const respForHour = aResp.find((r) => r.hours === key);
      if (quotePerHour[key]) {
        const obj = {
          "response_id": (respForHour)? respForHour.id : 0, //existing response id or 0
          "hours": key,
          "quotation": quotePerHour[key],
          "request_id": activeRequest.id,
          "is_not_available": false,
          "is_deleted": false
        };
        data.push(obj);
      } else if (aResp.length > 0 && respForHour && respForHour.quotation){
        // if the quotation is empty, and previous response quote is not empty, then delete the response
          const obj = {
            "response_id": respForHour.id,
            "hours": key,
            "quotation": null,
            "request_id": activeRequest.id,
            "is_not_available": false,
            "is_deleted": true
          };
          data.push(obj);
      }
    });
    if(data.length > 0){
      sendResponse(data);
    }
    roundRobin();
  }

  const roundRobin = () => { 
    // Round robin logic to go to next vehicle
    const totalPendingQuote = request.filter((r) => r.response.length === 0).length;
    if(totalPendingQuote > apiCallCounter +1){ // takes time to update counter so +1
      const findNextPendingQuoteIndex = request.findIndex((r, index) => vhIndex < index && r.response.length === 0); 
      const findFirstPendingQuoteIndex = request.findIndex((r) => r.response.length === 0); 
      if (findNextPendingQuoteIndex > -1) {
        setVhIndex(findNextPendingQuoteIndex);
      } else if (findFirstPendingQuoteIndex !== vhIndex){
        setVhIndex(findFirstPendingQuoteIndex);
      }
      setApiCallCounter(apiCallCounter + 1);
    } else if(isNMVCycleDone){
      // once non matching vehicle cycle is done, close the modal
      setIsModalOpen(false);
    } else {
      allQuoteSubmitted();
      setVhIndex(0);
    }
  }

  const allQuoteSubmitted = () => {
    // find all the matching vehicleId
    const matchingVehicles = [];
    const nonMatchingVehicles = [];
    request.forEach((r) => {
      matchingVehicles.push(parseInt(r.vehicles.id));
    });

    setIsAllQuoteSubmitted(true);
    setApiCallCounter(0); // reset API call counter
    setNMVLoading(true);
    affVehicles.forEach((v)=>{
      if(matchingVehicles.indexOf(parseInt(v.id)) === -1 
        && otherMatchingVehicles.indexOf(parseInt(v.id)) === -1){ // this is for sent quote page
        v['is_selected'] = false;
        nonMatchingVehicles.push(v);
      }
    });
    setNonMatchingVehicles(nonMatchingVehicles);
    setNMVLoading(false);
  }

  const updateQuotePrice = (hour, price) => {
    let qph = {...quotePerHour};
    qph[hour] = price ? parseInt(price) : null;
    setQuotePerHour(qph);
  }

  const quoteNonMatchingVehicle = ()=>{
    const leadId = request[0].lead_id;
    const rData = {
      vehicles: nonMatchingVehicles.filter(obj => obj.is_selected === true).map((v)=> parseInt(v.id)),
    }
    CreateRequest(leadId, rData).then((d) => {
      if (d.code === 200) {

        //stupid backend dev patch
        d.data.requests.forEach((r)=>{
          r['vehicles'] = r.vehicle;
          r['response'] = [];
        });
        // console.log('d.data.requests', d.data.requests);
        setRequest(d.data.requests);
        setIsAllQuoteSubmitted(false);
        setIsNMVCycleDone(true);
      }
    }).catch((e) => {
      console.log('e');
    });
    // populate request object to update the state
    // let _req =[];
    // nonMatchingVehicles.forEach((v)=>{
    //   if(v.is_selected){
    //     _req.push({
    //       "vehicles": {
    //         "id": v.id,
    //         "category": v.category,
    //         "color": v.color,
    //         "seat_fabric": v.seat_fabric,
    //         "passengers_count": v.passenger_count
    //       },
    //       "response": [], // empty response since non matching
    //       "hours": request[vhIndex].hours // hours will be same for all
    //     });
    //   }
    // });
  }

  const title = (isAllQuoteSubmitted)? 'Quotes Submitted!' : `Quote your price (${vhIndex+1}/${request.length})`;
  const allQuoteSubmittedBtns = [
    <Button
      key="skip"
      // type="primary"
      onClick={(e) => {
        setIsModalOpen(false);
        e.stopPropagation();
      }}
    >
      Skip
    </Button>,
    <Button
      key="submit"
      type="primary"
      onClick={() => {
        const count = nonMatchingVehicles.filter(obj => obj.is_selected === true).length;
        if(count > 0){
          quoteNonMatchingVehicle();
        } else {
          message.error('Please select at least one vehicle to quote');
        }
      }}
    >
      Quote Price {nonMatchingVehicles.filter(obj => obj.is_selected === true).length > 0 ? `(${nonMatchingVehicles.filter(obj => obj.is_selected === true).length})` : ''}
    </Button>
  ];

  const quotePriceBtns = [
    <Button
      key="sendAndNext"
      type="primary"
      onClick={() => {
        submitAndNext();
      }}
    >
      {(request.length > 1)? `Submit & go to next vehicle` : `Submit`}
    </Button>,
    <Button
      key="submit"
      // type="primary"
      onClick={() => {
        setNotInterested();
      }}
    >
      Not interested/available
    </Button>
  ];

  const backToLeads = [
    <Button
      key="backToLeads"
      type="primary"
      onClick={(e) => {
        setIsModalOpen(false);
        e.stopPropagation();
      }}
    >
      Back to lead screen
    </Button>
  ];

  const selectNonMatchingVehicle = (index, isSelected) => {
    const _v = [...nonMatchingVehicles];
    _v[index].is_selected = isSelected;
    setNonMatchingVehicles(_v);
  }

  const copyLine = {
    'one_way_transfer': 'The consumer is requesting a price for a one way trip. Please include your price for direct one way service from the pickup city to the destination city.',
    'round_trip': 'The consumer requires prices for a round trip at separate times. Please place pricing on any trips you are willing to service. Any trips that are not acceptable for this request, please leave that line blank.',
    'fixed_hours': 'The consumer requires price for multiple hourly packages. Please place pricing on any hourly package you are willing to service. Hourly packages that are not acceptable for this request, please leave that line blank.',
    'more_hours':  'The consumer requires price for multiple hourly packages. Please place pricing on any hourly package you are willing to service. Hourly packages that are not acceptable for this request, please leave that line blank.',
    'multiple_day_usage': 'The consumer requires price for multiple hourly packages. Please place pricing on any hourly package you are willing to service. Hourly packages that are not acceptable for this request, please leave that line blank.',
  }
  return (
    <Modal
      className="quote-price-modal-container"
      title={title}
      open={isModalOpen}
      centered
      width={600}
      onCancel={(e) => {
        setIsModalOpen(false);
        e.stopPropagation();
      }}
      footer={(isAllQuoteSubmitted)? ((nonMatchingVehicles.length > 0) ? allQuoteSubmittedBtns : backToLeads ): quotePriceBtns}
    >
      {!isAllQuoteSubmitted ?
      <div className='quote-price-container'>
        
        <div className="text-center">
          <span className={` ${request[vhIndex].vehicles.category} vehicle-select-list`}></span>
          <p>{VehicleCategorySingularName[request[vhIndex].vehicles.category]}</p>

          <div className="vehicle-spec-container jc-center">
            <div className="vehicle-single-spec">
              <PassengerCount />
              <span className="spec-text">{request[vhIndex].vehicles.passengers_count}</span>
            </div>
            {request[vhIndex].vehicles.seat_fabric && (
              <div className="vehicle-single-spec">
                <Seat />
                <span className="spec-text">{VehicleSeat[request[vhIndex].vehicles.seat_fabric]}</span>
              </div>
            )}
            <div className="vehicle-single-spec">
              {/* Setting color and background color for the quote */}
              <div className={`spec-color vehicle-color-list-${VehicleColor[request[vhIndex].vehicles.color]} ${(VehicleColor[request[vhIndex].vehicles.color] !== 'other') ? 'vehicle-color-black-border' : 'vehicle-color-non-black-border'}`}>
                {request[vhIndex].vehicles.color === 'other' && (
                  <span className="other-color-text">Other</span>
                )}
              </div>
              <span className="spec-text">{VehicleColor[request[vhIndex].vehicles.color]}</span>
            </div>
          </div>
        </div>
        <p> {copyLine[tripType]}</p>
        <div className="w-100 flex-separate">
          <div>
            &nbsp;
          </div>
          <div>
            <b>All-Inclusive Price</b>
          </div>
        </div>

        {roundTripData ?
          <>
            {request[vhIndex].hours.map((h, index) => {
            return (
              <div className="w-100" key={`quote-${h}-q-${vhIndex}-${index}`}>
                <div className="flex-separate">
                  <div className="quote-price-label round-trip">
                    {(index === 0 ?
                      <>
                        <p>
                          Starting at: {roundTripData.pickup_city} <br/>
                          {roundTripData.pickup_city} to {roundTripData.dropoff_city} 
                        </p>
                        {roundTripData.pickup_date} {roundTripData.pickup_time} Pick Up
                      </>
                      :
                      <>
                        <p>
                          Starting at: {roundTripData.dropoff_city} <br/>
                          {roundTripData.dropoff_city} to {roundTripData.pickup_city} 
                        </p>
                        {roundTripData.return_pickup_date} {roundTripData.return_pickup_time} Pick Up
                      </>
                     )}
                    
                  </div>
                  <div className="quote-price-input">
                    <b>{FormatTripHours(h)} {(index === 0) ? roundTripData.pickup_time : roundTripData.return_pickup_time}</b>
                    <Input 
                      placeholder="Price"
                      value={quotePerHour[h]}
                      maxLength={5}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                      }}
                      onChange={(e)=>updateQuotePrice(h, e.target.value)} />
                  </div>
                </div>
              </div>
            )})}
          </>
          :
          <>
          {request[vhIndex].hours.map((h, index) => {
          return (
            <div className="w-100" key={`quote-${h}-q-${vhIndex}-${index}`}>
              <div className="flex-separate">
                <div className="quote-price-label">
                  {FormatTripHours(h)} {h.length < 3 ? 'Package' : ''} 
                </div>
                <div className="quote-price-input">
                  <Input 
                    placeholder="Price"
                    value={quotePerHour[h]}
                    maxLength={5}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                    }}
                    onChange={(e)=>updateQuotePrice(h, e.target.value)} />
                </div>
              </div>
            </div>
          )})}
        </> }
        
      </div>
      :
      <div>
        <div className="flex-separate w-100 jc-center">
          <img src={VehicleCheck} alt="Vehicle Check" className="vehicle-check-image" />
          <div>
            <p>We have received the quoted price.</p>
            <p>We will check with the customer and get back to you shortly.</p>
          </div>
        </div>
        {nonMatchingVehicles.length > 0 &&
        <>
          <div>
            <h2>Send Quotes For More Of Your Available Vehicles?</h2>
            <p>If you have any <Text type='danger'>additional vehicle open</Text> that can service with this lead, please feel free to send quotes. We will present all of your quoted vehicles to the consumer giving you a better chance to book the run.</p>
          </div>
          <div className="nmv-container">
          
          <List
            itemLayout="horizontal"
            dataSource={nonMatchingVehicles}
            loading={isNMVLoading}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Checkbox checked={item.is_selected} onClick={(e)=>selectNonMatchingVehicle(index, e.target.checked)}/>}
                  title={VehicleCategorySingularName[item.category]}
                  description={<>
                    <div>
                        {item.passenger_count} passenger{(item.passenger_count > 1) ? 's' : ''} | <span className={`vehicle-list-color-icon vehicle-color-list-${VehicleColor[item.color]}`}></span>{VehicleColor[item.color]} {VehicleSeat[item.seat_fabric] ? `| ${VehicleSeat[item.seat_fabric]} seat` : ''}
                    </div> 
                  </>}
                />
              </List.Item>
            )}
          />
        </div></>}
      </div>
      }
    </Modal>
  )
};

export default QuotePrice;
