import React from 'react';
import pickUpImage from '../../assets/images/pickup.png';
import dropUpImage from "../../assets/images/drop.png";
import LeadVehicleCountSvg from '../../assets/images/svg/LeadVehicleCountSvg';
import { Status } from '../../constants';
const LeadAddress = ({ pickup, dropOff, vehicleLength, showMatchingVehicleBadge, status }) => {

    return (
        <div className='lead-view-container'>
            <div>
                <div className="leads-address">
                    <img
                        className="address-image"
                        src={pickUpImage}
                        alt="Pickup"
                    />
                    <div className="address-detail">
                        <div className="address-label">Pickup city</div>
                        <div className="address-text">{pickup}</div>
                    </div>
                </div>
                <div className="address-separator">
                    {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
                        <div className="address-separator-dots" key={'separator' + index} />
                    ))}
                </div>
                <div className="leads-address">
                    <img
                        className="address-image"
                        src={dropUpImage}
                        alt="Drop_off"
                    />
                    <div className="address-detail">
                        <div className="address-label">Drop off city</div>
                        <div className="address-text">{dropOff}</div>
                    </div>
                </div>
            </div>
            <div>
                {showMatchingVehicleBadge && <div className='leads-vehicle-count'>
                    <LeadVehicleCountSvg />
                    <span className="lead-vehicle-count">
                        {vehicleLength}
                    </span>
                </div>}
                <div style={{marginTop: '1.5rem'}}>
                    {(status === 'hired' || status === 'closed') && (
                        <div>
                            <div
                                className='status-label'
                                style={{
                                    backgroundColor: status === 'hired' ? '#F0FFF2' : '#EAEAEA',
                                    
                                }}
                            >
                                <span
                                    style={{
                                        color: status === 'hired' ? '#28B436' : '#AAAAAA',
                                    }}
                                >
                                    {Status[status]}
                                </span>
                            </div>
                            {status === 'closed' && (
                                <div className='closed-box'>
                                    <span style={{ color: '#FB3737', fontSize: '10px'}}>
                                        Consumer selected alternate affiliate
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default LeadAddress;