import React from 'react';
import { Row, Col } from 'antd';
import { getLeadDateStr, getLeadHoursStr, getLeadTimeStr } from '../../services/helper';
import SingleLeadAttribute from './single_lead_attribute';
import CalendarIcon from '../../assets/images/svg/CalendarIcon';
import ColoredPassengerCount from '../../assets/images/svg/coloredPassengerCount';
import Event from '../../assets/images/svg/Event';
import ThinTime from '../../assets/images/svg/ThinTime';
import TripIcon from '../../assets/images/svg/TripIcon';

const LeadAttributes = ({ attributes = {} }) => {
    const { pickUpDate, pickUpTime, hrsNeeded, tripType, event, tripDetails, approx_passenger_count, returnPickUpDate, returnPickUpTime } = attributes;

    const hSeparator = <div style={{ width: '0px' }} />;

    return (
        <div>
            {tripType === "round_trip" ? (
                <>
                    <Row gutter={[48, 40]}>
                        <Col span={12}>
                            <SingleLeadAttribute
                                label={'Trip 1 Date'}
                                value={getLeadDateStr(pickUpDate)}
                                icon={<CalendarIcon />}
                                color={'#101010'}
                                size={22}
                                noWrap />
                        </Col>
                        <Col span={12}>
                            <SingleLeadAttribute
                                label={'Pick Up Time'}
                                value={getLeadTimeStr(pickUpTime)}
                                icon={<ThinTime />}
                                color={'#101010'}
                                size={40} />
                        </Col>
                    </Row>
                    <Row gutter={[48, 40]}>
                        <Col span={12}>
                            <SingleLeadAttribute
                                label={'Trip 2 Date'}
                                value={getLeadDateStr(returnPickUpDate)}
                                icon={<CalendarIcon />}
                                color={'#101010'}
                                size={22}
                                noWrap />
                        </Col>
                        <Col span={12}>

                            <SingleLeadAttribute
                                label={'Pick Up Time'}
                                value={getLeadTimeStr(returnPickUpTime)}
                                icon={<ThinTime />}
                                color={'#101010'}
                                size={40} />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row gutter={[48, 40]}>
                        <Col span={12}>

                            <SingleLeadAttribute
                                label={'Pick Up Date'}
                                value={getLeadDateStr(pickUpDate)}
                                icon={<CalendarIcon />}
                                color={'#101010'}
                                size={22}
                                noWrap
                            />
                        </Col>
                        {hSeparator}
                        <Col span={12}>

                            <SingleLeadAttribute
                                label={'Pick Up Time'}
                                value={getLeadTimeStr(pickUpTime)}
                                icon={<ThinTime />}
                                color={'#101010'}
                                size={40}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <Row gutter={[48, 40]}>
                <Col span={12}>

                    <SingleLeadAttribute
                        label={'Hours Needed'}
                        value={getLeadHoursStr(hrsNeeded, tripType)}
                        icon={<ThinTime />}
                        color={'#101010'}
                        size={40}
                    />
                </Col>
                {hSeparator}
                <Col span={12}>

                    <SingleLeadAttribute
                        label={'Passengers'}
                        value={approx_passenger_count}
                        icon={<ColoredPassengerCount />}
                        color={'#101010'}
                        size={20}
                    />
                </Col>
            </Row>

            <SingleLeadAttribute
                label={'Event'}
                value={event}
                icon={<Event />}
                color={'#101010'}
                size={20}
            />
            <SingleLeadAttribute
                label={'Trip Details'}
                icon={<TripIcon />}
                color={'#101010'}
                size={20}
                valueView={
                    <div className="value-text">
                        {tripDetails}
                    </div>
                }
            />
        </div>
    )
}

export default LeadAttributes;