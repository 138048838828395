// Import Module
import React from 'react';
import { InputNumber } from 'antd';

const PhoneInput = ({value, onChange, placeholder='Enter phone number', ...props}) => {
    
    const parsePhoneNumber = (value) => {
        // Remove the +1 prefix
        value = value? value.toString().replace('+1 ', '') : '';
        // Remove non-numeric characters
        return value ? value.toString().replace(/\D/g, '') : '';
    };
    
    const formatPhoneNumber = (value) => {
        if (value === null || value === undefined) return '';
    
        // Format as XXX-XXX-XXXX
        const digits = value.replace(/\D/g, ''); // Remove non-numeric characters
        if (digits.length <= 3) {
          return digits;
        } else if (digits.length <= 6) {
          return `${digits.slice(0, 3)}-${digits.slice(3)}`;
        } else {
          return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
        }
    };
    
    const handleChange = (value) => {
        onChange(formatPhoneNumber(parsePhoneNumber(value)));
    };

    return (
        <InputNumber 
            addonBefore="+1" 
            maxLength={12} 
            placeholder={placeholder} 
            value={parsePhoneNumber(value)} 
            formatter={formatPhoneNumber}
            parser={parsePhoneNumber}
            onChange={handleChange}
            onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
            {...props}
        />
    );
};

export default PhoneInput;