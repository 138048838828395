// Import Module
import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';

import CompanyDetails from '../components/company_details';
import { GetCookie, ValidateZIP, ValidatePhone, ValidateURL, ValidateEmail, FormatPhoneNumberForServer } from '../services/helper';
import { GetAffiliateDetails, UpdateAffiliateDetails } from '../services/api';
import FullSpin from '../components/full_spin';

const CompanyInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const id = GetCookie('AffiliateID');
  const key = 'updatable';

  useEffect(() => {
    GetAffiliateDetails(id).then((d) => {
      if (d.code === 200) {
        // cross state patch
        d.data.is_cross_state_insured = (d.data.is_cross_state_insured)? 'Yes' : 'No';
        setData(d.data);
        setIsLoading(false);
      }
    }).catch((e) => {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
      });
    });
  }, []); //eslint-disable-line
  
  const validateForm = () => {
    let _data = data;
    let errorMsg = '';
    if (!_data.name) {
      errorMsg = 'Company name is required!';
    }
    else if (!_data?.address?.street_address) {
      errorMsg = `Full address of company is required!`;
    }
    else if (!_data?.address?.zipcode) {
      errorMsg = `Zip code is required!`;
    }
    else if (!ValidateZIP(_data.address.zipcode)) {
      errorMsg = `Invalid zip code! Only use numeric values in either "10001" formats.`;
    }
    else if (!_data.phone_number) {
      errorMsg = `Office phone number is required!`;
    }
    else if (_data.phone_number.length < 10 || !ValidatePhone(_data.phone_number)) {
      errorMsg = `Invalid office phone number!`;
    }
    else if (!_data.email) {
      errorMsg = `Email address is required!`;
    }
    else if (!ValidateEmail(_data.email)) {
      errorMsg = `Please enter a valid email address.`;
    }
    else if (!_data.website) {
      errorMsg = `Company website is required!`;
    }
    // check if website contains spaces
    else if (_data.website.includes(' ')) {
      errorMsg = `Space not allowed in website!`;
    }
    else if (!ValidateURL(_data.website)) {
      errorMsg = `Invalid Company website!`;
    }
    else if (_data.dot_number && _data.dot_number.length > 10) {
      errorMsg = `Please enter valid DOT number.`;
    }
    else if(!_data.is_cross_state_insured){
      errorMsg = `Cross state lines selection is required!`;
    }

    if(errorMsg){
      messageApi.open({
        key,
        type: 'error',
        content: errorMsg,
      });
      return false;
    }
    else {
      return true;
    }
  }

  return (
    <div className='add-affiliate-container company-info-container'>
      {contextHolder}
      {isLoading && <FullSpin />}
      <div className='company-detail-container'>
        <h1>Company Information</h1>
        <CompanyDetails 
          data={data}
          setData={setData}/> 
        <Button size='large' 
          block={true} 
          type='primary' 
          loading={isLoading} 
          onClick={() => {
            const isValid = validateForm();
            if(isValid){
              // Save data
              setIsLoading(true);
              //trim email space
              if(data.email){
                data.email = data.email.trim();
              }
              // Patch for phone number which append +1
              data.phone_number = FormatPhoneNumberForServer(data.phone_number);
              UpdateAffiliateDetails(id, data).then((d) => {
                if (d.code === 200) {
                  messageApi.open({
                    key,
                    type: 'success',
                    content: 'Company Information updated successfully!',
                  });
                  setIsLoading(false);
                }
              }).catch((e) => {
                messageApi.open({
                  key,
                  type: 'error',
                  content: e.message,
                });
                setIsLoading(false);
              });
            }
        }}>Save</Button>
      </div>
    </div>
  )
};

export default CompanyInfo;