import React from 'react';
import ClockIcon from '../../assets/images/svg/ClockIcon';
import CalendarIcon from '../../assets/images/svg/CalendarIcon';
import { getLeadDateStr, getLeadHoursStr, getLeadTimeStr } from '../../services/helper';
import dayjs from 'dayjs';

const LeadDateTime = ({ date, time, hrs, tripType }) => {

    const day = dayjs(date).format('dddd');
    // const day = dt.weekday();

    return (
        <div className="leads-date-time">
            <div className="item-wrapper">
                <CalendarIcon />
                <div className="date-view">
                    <div className="item-text">{getLeadDateStr(date)}</div>
                    <div className="item-text">{day}</div>
                </div>
            </div>
            <div className="item-wrapper">
                <ClockIcon />

                <span className="item-text">{getLeadTimeStr(time)}</span>
            </div>
            <div className="item-wrapper">
                <ClockIcon />
                <div>
                    {/* If the trip is round-trip then we use this */}
                    {tripType === "round_trip" ? (
                        <div className="date-view">
                            <div className="item-text">Round</div>
                            <div className="item-text">Trip</div>
                        </div>
                    ) : (
                        <div>{getLeadHoursStr(hrs, tripType)}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default LeadDateTime;