import React from 'react'

const LeadVehicleCountSvg = () => {
    return (
        <svg viewBox="0 0 40 15" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.9009 12.108H31.396M5.32537 12.108H3.82537C3.62757 12.108 3.52867 12.108 3.43368 12.0989C3.03322 12.0606 2.65361 11.9025 2.34444 11.6451C2.27111 11.584 2.20147 11.5138 2.0622 11.3733V11.3733C1.92453 11.2345 1.8557 11.1651 1.79584 11.0921C1.54352 10.7844 1.38871 10.4084 1.35123 10.0123C1.34234 9.91829 1.34234 9.82053 1.34234 9.62501V3.95175C1.34234 3.57815 1.34234 3.39135 1.37397 3.21561C1.43763 2.86198 1.59538 2.53202 1.83064 2.26043C1.94755 2.12546 2.09293 2.00817 2.3837 1.77358V1.77358C2.60046 1.5987 2.70884 1.51126 2.82505 1.44108C3.05856 1.30005 3.31845 1.20828 3.58874 1.17142C3.72325 1.15308 3.8625 1.15308 4.14101 1.15308H26.9961C27.9135 1.15308 28.3721 1.15308 28.774 1.34048C29.1759 1.52788 29.4707 1.87927 30.0604 2.58204L31.6455 4.47126C32.2351 5.17403 32.53 5.52542 32.9318 5.71282C33.3337 5.90022 33.7924 5.90022 34.7097 5.90022H35.8962C36.3487 5.90022 36.5749 5.90022 36.7885 5.94751C37.0194 5.99864 37.2394 6.09032 37.4383 6.21829C37.6223 6.33666 37.7816 6.4973 38.1002 6.81859V6.81859C38.4151 7.13618 38.5725 7.29497 38.6885 7.47766C38.8139 7.67519 38.9037 7.89319 38.9537 8.12174C39 8.33313 39 8.55676 39 9.004V9.25744C39 9.76377 39 10.0169 38.9452 10.2463C38.846 10.6611 38.6168 11.0333 38.2911 11.3086C38.111 11.4608 37.885 11.5748 37.4329 11.8028V11.8028C37.2533 11.8933 37.1635 11.9386 37.0707 11.9743C36.9035 12.0388 36.7284 12.0804 36.55 12.0982C36.4511 12.108 36.3506 12.108 36.1494 12.108H35.9189" stroke="#101010" />
            <path d="M3.39636 1.15308V3.94587C3.39636 5.05044 4.29179 5.94587 5.39636 5.94587H35.5765" stroke="#101010" />
            <line y1="-0.5" x2="4.79279" y2="-0.5" transform="matrix(0 1 1 0 25.3063 1.15308)" stroke="#101010" />
            <line y1="-0.5" x2="4.79279" y2="-0.5" transform="matrix(0 1 1 0 19.1442 1.15308)" stroke="#101010" />
            <line x1="0.5" y1="-0.5" x2="18.6712" y2="-0.5" transform="matrix(-1 0 0 1 30.0991 10.054)" stroke="#101010" strokeLinejoin="round" />
            <line y1="-0.5" x2="4.79279" y2="-0.5" transform="matrix(0 1 1 0 12.9819 1.15308)" stroke="#101010" />
            <line y1="-0.5" x2="4.79279" y2="-0.5" transform="matrix(0 1 1 0 6.81982 1.15308)" stroke="#101010" />
            <circle cx="2.73874" cy="2.73874" r="2.23874" transform="matrix(-1 0 0 1 10.2433 9.36938)" stroke="#101010" />
            <circle cx="2.73874" cy="2.73874" r="2.23874" transform="matrix(-1 0 0 1 36.2612 9.36938)" stroke="#101010" />
            <circle cx="0.684685" cy="0.684685" r="0.684685" transform="matrix(-1 0 0 1 8.18921 11.4233)" fill="#101010" />
            <circle cx="0.684685" cy="0.684685" r="0.684685" transform="matrix(-1 0 0 1 34.2072 11.4233)" fill="#101010" />
            <path d="M2.02699 9.55395C2.50713 9.55395 2.89636 9.16472 2.89636 8.68459C2.89636 8.20445 2.50713 7.81522 2.02699 7.81522L1.49997 7.81522L1.49997 9.55395L2.02699 9.55395Z" stroke="#101010" />
            <mask id="path-13-inside-1_944_2236" fill="white">
                <path d="M37.973 9.36938C37.7839 9.36938 37.6306 9.52266 37.6306 9.71173C37.6306 9.9008 37.7839 10.0541 37.973 10.0541L39 10.0541L39 9.36938L37.973 9.36938Z" />
            </mask>
            <path d="M37.973 9.36938C37.7839 9.36938 37.6306 9.52266 37.6306 9.71173C37.6306 9.9008 37.7839 10.0541 37.973 10.0541L39 10.0541L39 9.36938L37.973 9.36938Z" fill="#138DF5" />
            <path d="M39 10.0541L40 10.0541L40 11.0541L39 11.0541L39 10.0541ZM39 9.36938L39 8.36938L40 8.36938L40 9.36938L39 9.36938ZM37.973 9.05407L39 9.05407L39 11.0541L37.973 11.0541L37.973 9.05407ZM38 10.0541L38 9.36938L40 9.36938L40 10.0541L38 10.0541ZM39 10.3694L37.973 10.3694L37.973 8.36938L39 8.36938L39 10.3694ZM38.6306 9.71173C38.6306 9.34851 38.3362 9.05407 37.973 9.05407L37.973 11.0541C37.2316 11.0541 36.6306 10.4531 36.6306 9.71173L38.6306 9.71173ZM36.6306 9.71173C36.6306 8.97037 37.2316 8.36938 37.973 8.36938L37.973 10.3694C38.3362 10.3694 38.6306 10.0749 38.6306 9.71173L36.6306 9.71173Z" fill="#101010" mask="url(#path-13-inside-1_944_2236)" />
        </svg>
    )
}

export default LeadVehicleCountSvg