// Import Module
import React, { useEffect} from 'react';
import { Button} from 'antd';
import { useNavigate } from 'react-router-dom';

import { GetCookie } from '../services/helper';
import logo from '../assets/images/price4limo-logo.jpg';
import limoSun from '../assets/images/limo-sun.png';

const Home = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const accessToken = GetCookie('AffiliateAccessToken');
    if (accessToken) {
      navigate('/leads');
    }
  }, [navigate]);

  return (
    <div className='home-wrapper'>
      <div className='home-logo-wrapper w3-animate-top'>
        <img src={logo} alt='price 4 limo' className='login-logo' />
      </div>
      <div className='w3-animate-bottom'>
        <div className='home-extra-logo'>
          <img src={limoSun} alt='price 4 limo background' className='login-sun' />
        </div>
        <br />
        <h4 className='text-center'>Free Leads Nationwide For Charter Buses, Party Buses, Limousines</h4>
        <br />
        <div className='text-center home-btn-wrapper'>
          <Button
            type='primary'
            className='btn btn-primary'
            size='large'
            onClick={()=> navigate('/login')}
          >
            Login
          </Button>

          <Button
            className='btn'
            size='large'
            onClick={()=> navigate('/register')}
          >
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
