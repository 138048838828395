import React, { useMemo } from 'react';
import dayjs from 'dayjs'

const SingleLeadAttribute = ({ label, value, icon, valueView, color, size, noWrap }) => {

    const day = useMemo(() => {
        if (label === 'Pick Up Date' || label === 'Trip 1 Date' || label === 'Trip 2 Date') {
            const dateString = value;
            return dayjs(dateString).format('dddd');
        }
    }, [label, value]);


    return (
        <div className="singleAttr-container">
            <div className="icon-container">
                {icon}
            </div>
            <div >
                <div className="label-text ">{label}</div>
                {label === 'Passengers' && <span className="approx-text">Approximately{" "}</span>}
                {label === 'Hours Needed' && !value && <div className="value-text">Round Trip</div>}
                {valueView ? valueView : <span className="value-text">{value}</span>}
                {['Pick Up Date', 'Trip 1 Date', 'Trip 2 Date'].includes(label) && <span className="value-text">{" "}{day}</span>}
            </div>
        </div>
    );
};

export default SingleLeadAttribute;
