// Import Module
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Steps, Input, message, List } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { RegisterAffiliate, LoginAPI } from '../services/api';
import { ValidateEmail, ValidateURL, ValidatePhone, GetCookie, SetCookie,
   ValidateZIP, FormatPhoneNumberForServer, IsStrongPassword } from '../services/helper';
import { VehicleCategory, VehicleColor, VehicleSeat } from '../constants'
import {
  // CheckCircleTwoTone,
  // CloseCircleTwoTone,
  LockOutlined,
} from '@ant-design/icons';

import Contact from '../components/contact';
import VehicleInfoModal from '../components/vehicle_info_modal';
import CompanyDetails from '../components/company_details';

const key = 'updatable';
const Password =({ savePassword, cpass, pass, saveCPassword})=>{

  return(
    <>
    <div className='mb-3 mt-10'>
        <label className='add-affiliate-form-item-label'>Password</label>
        <Input.Password
          placeholder='Enter new password'
          maxLength={48}
          value={pass}
          prefix={<LockOutlined className='site-form-item-icon' />}
          onChange={(e) => savePassword(e.target.value)}
        />
      </div>
      <div className='mb-3 mt-10'>
        <label className='add-affiliate-form-item-label'>Re-type Password</label>
        <Input.Password
          maxLength={48}
          placeholder='Re-type password'
          className='form-control'
          value={cpass}
          onChange={(e) => saveCPassword(e.target.value)}
          prefix={<LockOutlined className='site-form-item-icon' />}
        />
      </div>
    </>
  )
};

const VehicleList = ({ saveVehicle, messageApi, vehicleData }) => {
  const [vehicles, setVehicles] = useState(vehicleData?.length > 0 ? vehicleData : []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModeIndex, setEditModeIndex] = useState(-1);

  const addVehicle = (vehicle) => {
    // check for duplication
    if (vehicles.some((v) => 
      v.category === vehicle.category &&
      v.color === vehicle.color &&
      v.seat_fabric === vehicle.seat_fabric &&
      v.passenger_count === vehicle.passenger_count)) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Vehicle already exists!',
      });
      return;
    }
    let _data = [...vehicles];
    if (editModeIndex > -1) {
      _data[editModeIndex] = vehicle; // edit
      setEditModeIndex(-1); // reset
    } else {
      _data.push(vehicle); // add new
    }
    setVehicles(_data);
    saveVehicle(_data);
    setIsModalOpen(false);
  }

  const removeVehicle = (index) => {
    let _data = [...vehicles];
    _data.splice(index, 1);
    setVehicles(_data);
    saveVehicle(_data);
  };

  const editVehicle = (index) => {
    setIsModalOpen(true);
    setEditModeIndex(index);
  }

  return (
    <div className='vehicle-list-container'>
      {isModalOpen && <VehicleInfoModal 
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        addVehicleIntoList={addVehicle}
        editModeIndex={editModeIndex}
        loading={false}
        messageApi={messageApi}
        vehicle={editModeIndex > -1 ? vehicles[editModeIndex] : null}
      />}
      <div className='flex-separate'>
        <h3 className='mt-20'>Vehicle List</h3>
        <Button type="primary" className='mt-10' onClick={() => {
          setEditModeIndex(-1); // reset for new vehicle
          setIsModalOpen(true)
        }}>Add Vehicle</Button>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={vehicles}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <EditOutlined className='vehicle-action-icon' onClick={(e) => editVehicle(index)} />,
              <DeleteOutlined className='vehicle-action-icon' onClick={(e) => removeVehicle(index)} />]}>
            <List.Item.Meta
              avatar={<span className={` ${item.category} vehicle-select-list`}></span>}
              title={VehicleCategory[item.category]}
              description={
                <div>{item.passenger_count} passenger{(item.passenger_count > 1) ? 's' : ''} | <span className={`vehicle-list-color-icon vehicle-color-list-${VehicleColor[item.color]}`}></span>{VehicleColor[item.color]} {VehicleSeat[item.seat_fabric]? `| ${VehicleSeat[item.seat_fabric]} seat` : ''}</div>}
            />
          </List.Item>
        )
        }
      />
    </div >
  )
}

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [current, setCurrent] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = GetCookie('AffiliateAccessToken');
    if (accessToken) {
      navigate('/leads');
    }
  }, [navigate]);

  const validateFirstStep = () => {
    let _data = data;
    let errorMsg = '';
    if (!_data.name) {
      errorMsg = 'Company name is required!';
    }
    else if (!_data?.address?.street_address) {
      errorMsg = `Full address of company is required!`;
    }
    else if (!_data?.address?.zipcode) {
      errorMsg = `Zip code is required!`;
    }
    else if (!ValidateZIP(_data.address.zipcode)) {
      errorMsg = `Invalid zip code! Only use numeric values in either "10001" formats.`;
    }
    else if (!_data.phone_number) {
      errorMsg = `Office phone number is required!`;
    }
    else if (_data.phone_number.length < 10 || !ValidatePhone(_data.phone_number)) {
      errorMsg = `Invalid office phone number!`;
    }
    else if (!_data.email) {
      errorMsg = `Email address is required!`;
    }
    else if (!ValidateEmail(_data.email)) {
      errorMsg = `Please enter a valid email address.`;
    }
    else if (!_data.website) {
      errorMsg = `Company website is required!`;
    }
    // check if website contains spaces
    else if (_data.website.includes(' ')) {
      errorMsg = `Space not allowed in website!`;
    }
    else if (!ValidateURL(_data.website)) {
      errorMsg = `Invalid Company website!`;
    }
    else if (_data.dot_number && _data.dot_number.length > 10) {
      errorMsg = `Please enter valid DOT number.`;
    }
    else if(!_data.is_cross_state_insured){
      errorMsg = `Cross state lines selection is required!`;
    }

    if(errorMsg){
      messageApi.open({
        key,
        type: 'error',
        content: errorMsg,
      });
      return false;
    }
    else {
      return true;
    }
  }

  const validateSecondStep = () => {
    if (!data?.contacts || data?.contacts?.length === 0) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please add at least one contact.`,
      });
      return false;
    } else {
      let contacts = data.contacts;
      const isProper = contacts.map((contact, index) => {
        if (!contact.name) {
          messageApi.open({
            key,
            type: 'error',
            content: `Name is required!`,
          });
          return false;
        } else if (!contact.email) {
          messageApi.open({
            key,
            type: 'error',
            content: `Email address is required!`,
          });
          return false;
        } else if (!ValidateEmail(contact.email) || contact.email.includes(' ')) {
          messageApi.open({
            key,
            type: 'error',
            content: `Please enter a valid email address.`,
          });
          return false;
        } else if (!contact.phone_number) {
         messageApi.open({
           key,
           type: 'error',
           content: `Mobile number is required!`,
         });
         return false;
       } else if (contact.phone_number.length < 10
           || !ValidatePhone(contact.phone_number)
        ) {
          messageApi.open({
            key,
            type: 'error',
            content: `Invalid mobile number!`,
          });
          return false;
        }
        return true;
      });
      return !isProper.includes(false);
    }
  }

  const next = () => {
    if (current === 0) {
      const isValidated = validateFirstStep();
      if (!isValidated) {
        return;
      }
    } else if (current === 1) {
      const isValidated = validateSecondStep();
      if (!isValidated) {
        return;
      }
    } else if (current === 2) {
      if (!data?.vehicles || data?.vehicles?.length === 0) {
        messageApi.open({
          key,
          type: 'error',
          content: `Please add at least one vehicle.`,
        });
        return;
      }
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const saveContact = (contacts) => {
    let _data = { ...data };
    _data['contacts'] = contacts;
    setData(_data);
  }

  const saveVehicle = (vehicles) => {
    let _data = { ...data };
    _data['vehicles'] = vehicles;
    setData(_data);
  }

  const savePassword = (password) => {
    let _data = { ...data };
    _data['password'] = password;
    setData(_data);
  };

  const saveCPassword = (password) => {
    let _data = { ...data };
    _data['confirm_password'] = password;
    setData(_data);
  };

  const steps = [
    {
      title: 'Company Details',
      content: <CompanyDetails data={data} setData={setData}/>,
    },
    {
      title: 'Contact Details',
      content: <Contact isPartialForm={false} saveContact={saveContact} contactData={data?.contacts} />,
    },
    {
      title: 'Vehicle Details',
      content: <VehicleList saveVehicle={saveVehicle} messageApi={messageApi}
        vehicleData={data?.vehicles} />,
    },
    {
      title: 'Password',
      content: <Password 
        savePassword={savePassword}
        saveCPassword={saveCPassword}
        pass={data?.password}
        cpass={data?.confirm_password} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const registerAffiliateData = () => {
    if (!data?.password) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Password is required!',
      });
      return;
    } else if (!IsStrongPassword(data?.password)) {
      messageApi.open({
        key,
        type: 'error',
        content: "Password should be minimum of 8 characters and cannot contain whitespace.",
      });
      return;
    } else if (data?.password !== data?.confirm_password) {
      messageApi.open({
        key,
        type: 'error',
        content: "Passwords don't match.",
      });
      return;
    }
    setLoading(true);
    // const payload = {
    //   "name": "Max mark 1",
    //   "website": "localhost.com",
    //   "email": "maxmark14@gmailt.com",
    //   "password": '12345678',
    //   "phone_number": "+1 8069787857",
    //   "dot_number": "",
    //   "is_cross_state_insured": false,
    //   "address": {
    //     "street_address": "Killen max mark",
    //     "zipcode": "35645"
    //   },
    //   "contacts": [
    //     {
    //       "is_primary": true,
    //       "name": "max primary",
    //       "email": "maxprimary14@pgmailt.com",
    //       "phone_number": "+1 8064548688"
    //     },
    //   ],
    //   "vehicles": [
    //     {
    //       "category": "charter_bus",
    //       "passenger_count": "1",
    //       "color": "white",
    //       "seat_fabric": "cloth",
    //       "status": "available"
    //     }
    //   ]
    // };


    // Patch for phone number which append +1
    data.phone_number = FormatPhoneNumberForServer(data.phone_number);
    data.email = data.email.trim(); // trim spaces
    data.contacts.forEach((contact) => {
      contact.phone_number = FormatPhoneNumberForServer(contact.phone_number);
      contact.email = contact.email.trim(); // trim spaces
    });

    RegisterAffiliate(data).then((response) => {
      if (response.code === 200) {
        messageApi.open({
          key,
          type: 'success',
          content: `Registration completed. Logging you in.`,
        });
        
        // Auto login
        const loginData = {
          email: data.contacts[0].email.trim(),
          password: data.password.trim()
        };
        setLoading(true);
        LoginAPI(loginData)
          .then((d) => {
            setLoading(false);
            if (d.code === 200) {
              const { token, refreshToken } = d?.data?.tokens;
              const { name, affiliate_id } = d?.data?.user_details;
              messageApi.open({
                key,
                type: 'success',
                content: `Welcome ${name}!`,
              });
              if (token) {
                SetCookie('AffiliateAccessToken', token, 30);
                SetCookie('AffiliateRefreshToken', refreshToken, 30);
                SetCookie('AffiliateUser', name, 30);
                SetCookie('AffiliateID', affiliate_id, 30);
                setTimeout(() => {
                  navigate('/leads');
                }, 1000);
              }
            } else {
              messageApi.open({
                key,
                type: 'error',
                content: 'Email or Password seems to be invalid.',
              });
            }
          })
          .catch((e) => {
            setLoading(false);
            messageApi.open({
              key,
              type: 'error',
              content: e.message,
            });
          });
      }
    }).catch((e) => {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
      });
      setLoading(false);
    });
  }

  const copyTxt = [
    {
      title: 'Sign up',
      content: 'Please fill out the following information to enroll your account free and receive leads from Price 4 Limo'
    },
    {
      title: 'Add Primary Contact Information',
      content: 'This will be the main contact person from your team who will receive and respond to Price 4 Limo lead requests.'
    },
    {
      title: 'Add Vehicles',
      content: 'Please click on all relevant categories of vehicles that you currently own in your fleet.'
    },
    {
      title: 'Create new password',
      content: `Password must be at least 8 digits. Your login email address is: ${(data?.contacts?.length > 0)? data?.contacts[0]?.email : ''}`
    }
  ];
  return (
    <div className='add-affiliate-container black-bg'>
      {contextHolder}
      <h1>{copyTxt[current].title}</h1>
      <div className='flex-separate'>
        <h4>{copyTxt[current].content}</h4>
        <Button className='white' type='link' onClick={() => navigate('/login')}> Back to Login</Button>
      </div>
      <div className='add-affiliate-section'>
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={registerAffiliateData} loading={loading}>
              Set Password
            </Button>
          )}

        </div>
      </div>
    </div >
  );
};

export default Register;
