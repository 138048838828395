import React from 'react';
import LeadAddress from '../LeadComponents/lead_address';
import LeadDateTime from '../LeadComponents/lead_date_time';
import TripDetails from '../LeadComponents/trip_details';
import { getLeadAddressStr } from '../../services/helper';

const LeadCard = ({ leadData, onClick, uniqueKey }) => {

    return (
        <div className='lead-card' onClick={() => onClick(leadData.id)}>
            <div key={uniqueKey}>
                {/* Address card component */}
                <LeadAddress
                    pickup={getLeadAddressStr(leadData.pickup_city, leadData.pickup_state)}
                    dropOff={getLeadAddressStr(leadData.dropoff_city, leadData.dropoff_state)}
                    vehicleLength={leadData.request.length}
                    showMatchingVehicleBadge={true}
                    status={''}
                />
                {/* Lead Time and Date Component */}
                <LeadDateTime
                    date={leadData.pickup_date}
                    time={leadData.pickup_time}
                    hrs={leadData.trip_hours}
                    tripType={leadData.trip_type}
                />
            </div>
            {/* Trip Details */}
            <TripDetails tripDetail={leadData.trip_details || ""} />
        </div>
    )
}

export default LeadCard;