import React from 'react'

const CalendarIcon = () => {
    return (
        <svg viewBox="0 0 14 15" height="20" width="20" fill="none" >
            <path d="M11.71 2.54468H2.33875C1.59938 2.54468 1 3.09795 1 3.78045V12.4309C1 13.1134 1.59938 13.6666 2.33875 13.6666H11.71C12.4494 13.6666 13.0488 13.1134 13.0488 12.4309V3.78045C13.0488 3.09795 12.4494 2.54468 11.71 2.54468Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.03252 1V3.47155M4.08943 1V3.47155M1 5.94309H12.5854" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CalendarIcon