// src/firebase.js
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import {SetFirebaseToken} from './services/api';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID
}; 

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const requestNotificationPermission = async () => {
    try {
      await Notification.requestPermission();
      // const token = await messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY });

      // New change 

      messaging.getToken({
        vapidKey : process.env.REACT_APP_VAPID_KEY,
      }).catch((err) => {
        // https://github.com/firebase/firebase-js-sdk/issues/7693
        const error = "AbortError: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker";
        if (err.toString() === error) {
            return messaging.getToken({
                vapidKey : process.env.REACT_APP_VAPID_KEY,
            });
        } else {
            throw err;
        }
    })
    .then((currentToken) => {
        if (currentToken) {
          // Sending token to backend
          SetFirebaseToken(currentToken).then(() => {
        }).catch((e) => {
          console.log("Error", e.message)
        });
        return currentToken;
        } else {
            return null;
        }
    })
    .catch((err) => {
        console.error(err);
    });
      // --------------------------
      // Send the token to your backend server
      // API missing 
    } catch (error) {
      console.error('Error getting notification permission:', error);
    }
  };

export { messaging, requestNotificationPermission };