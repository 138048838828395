import React from 'react'

const Seat = () => {
    return (
        <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.41602 18.5226C4.37653 18.4634 4.34887 18.4144 4.33104 18.375L4.35194 18.3578C4.37342 18.3401 4.40534 18.3139 4.4465 18.2804C4.52882 18.2133 4.64796 18.1173 4.7942 18.0018C5.08709 17.7706 5.48665 17.4633 5.91562 17.1569C6.34647 16.8491 6.79782 16.5489 7.19595 16.3277C7.367 16.2327 7.52169 16.1559 7.65591 16.1H8H13C13.8239 16.1 14.5 16.7761 14.5 17.6V18.6C14.5 19.4239 13.8239 20.1 13 20.1H7C6.16084 20.1 5.52482 19.7247 5.08448 19.3284C4.86457 19.1304 4.69906 18.9318 4.58928 18.7836C4.53463 18.7099 4.4945 18.6495 4.46898 18.6093C4.45624 18.5893 4.4472 18.5743 4.44186 18.5653L4.43708 18.5572L4.43689 18.5568L4.43672 18.5565L4.43667 18.5564L4.43645 18.556L4.43617 18.5556L4.42725 18.5395L4.41602 18.5226ZM2.99549 1C2.99748 1.00008 3.00152 1.00027 3.00735 1.00068C3.0214 1.00169 3.04473 1.00394 3.07405 1.00882C3.13505 1.01899 3.20875 1.03839 3.27639 1.07221C3.34208 1.10506 3.39255 1.14647 3.42772 1.19923C3.46081 1.24885 3.5 1.33749 3.5 1.5C3.5 1.74622 3.42619 1.85225 3.24243 2.11622C3.19663 2.18202 3.144 2.25762 3.08397 2.34765C2.7839 2.79777 2.5 3.39318 2.5 4.5C2.5 5.12269 2.73265 5.71028 3.0298 6.25505C3.26665 6.68927 3.56803 7.13612 3.86559 7.57731C3.93896 7.68609 4.0121 7.79453 4.08397 7.90235C4.83448 9.02811 5.5 10.1654 5.5 11.5C5.5 12.831 4.8326 13.9687 4.12037 14.7996C3.80478 15.1678 3.48839 15.4662 3.23529 15.6833C3.16911 15.4976 3.09098 15.276 3.00374 15.0239C2.7241 14.2161 2.3514 13.098 1.97891 11.8563C1.22743 9.35139 0.5 6.41713 0.5 4.5C0.5 2.57707 0.980393 1.72671 1.35355 1.35355C1.54247 1.16463 1.72297 1.07772 1.84561 1.03684C1.90781 1.01611 1.95636 1.00685 1.98477 1.00279C1.99673 1.00108 2.00497 1.00031 2.009 1H2.99549Z" stroke="#101010" />
        </svg>
    )
}

export default Seat