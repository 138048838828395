// Import Module
import React, { useEffect, useMemo, useState } from 'react';
import {List, message, Button, Row, Col, Typography } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import {
    GetLeadDetails, SetLeadResponse, UpdateLeadResponse, GetMatchingVehicle, TrackLead
} from '../services/api';
import QuotePrice from '../components/quote_price';
import LeadAttributes from '../components/LeadComponents/lead_attributes';
import { Events, VehicleSeat, VehicleCategorySingularName, VehicleColor} from '../constants';
import { FormatTripHours, getLeadAddressStr, GetRoundTripData } from '../services/helper';
import BackBtn from '../components/back_btn';
import FullSpin from '../components/full_spin';
import pickUpImage from '../assets/images/pickup.png';
import dropUpImage from "../assets/images/drop.png";
import PassengerCount from '../assets/images/svg/PassengerCount';
import Seat from '../assets/images/svg/Seat';
import ThinTime from '../assets/images/svg/ThinTime';

const {Text} = Typography;
const LeadDetails = () => {

    const [data, setData] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [reqForQuote, setReqForQuote] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isNIProcessing, setIsNIProcessing] = useState(false); // not interested processing
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quoteRequestIndex, setquoteRequestIndex] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [roundTripData, setRoundTripData] = useState(null);
    const [otherMatchingVehicles, setOtherMatchingVehicles] = useState([]); // other matching vehicles
    const key = 'updatable';

    const leadAttribute = useMemo(() => {
        return data
            ? {
                pickUpDate: data.pickup_date,
                pickUpTime: data.pickup_time,
                hrsNeeded: data.trip_hours,
                tripType: data.trip_type,
                passengers: data.passenger_count,
                event: Events[data.trip_event],
                tripDetails: data.trip_details || "",
                approx_passenger_count: data.approx_passenger_count,
                returnPickUpDate: data.return_pickup_date || "",
                returnPickUpTime: data.return_pickup_time || "",
            }
            : {
                pickUpDate: "",
                pickUpTime: "",
                hrsNeeded: "",
                passengers: "",
                event: ``,
                tripDetails: "",
                tripType: "",
                approx_passenger_count: "",
                returnPickUpDate: "",
                returnPickUpTime: "",
            };
    }, [data]);

    const getLeadDetail = () => {
        GetLeadDetails(id).then((d) => {
            if (d.code === 200) {
                setData(d.data?.lead);
                setRoundTripData(GetRoundTripData(d.data.lead));
                updateRequestData();
            }
        }).catch((e) => {
            messageApi.open({
                key,
                type: 'error',
                content: e.message,
            });
            setIsLoading(false);
            // if lead not found, redirect to lead list
            setTimeout(() => {
                navigate('/leads');
            }, 1000);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        getLeadDetail(true);

        setTimeout(() => {
            TrackLead(id).catch((e) => {
                console.log(e);
            });
        }, 2000);

        /* eslint-disable-next-line*/
    }, [id]);

    const setNotInterested = () => {
        setIsNIProcessing(true);
        // this should be combination of POST & PUT
        let requests = [];
        let data = [];
        vehicles.forEach((item) => {
            data = [];
            if(item.response.length > 0) {
                item.response.forEach((res) => {
                    const obj ={
                        'hours': res.hours,
                        'response_id': res.id,
                        'quotation': null,
                        'request_id': item.id,
                        'is_not_available': true,
                        'is_deleted': false,
                    };
                    data.push(obj);
                });
                requests.push(UpdateLeadResponse(id, data));
            } else{
                const obj ={
                    'hours': null,
                    'response_id': 0,
                    'quotation': null,
                    'request_id': item.id,
                    'is_not_available': true,
                    'is_deleted': false,
                };
                data.push(obj);
                SetLeadResponse(id, data);
                requests.push(SetLeadResponse(id, data));
            }
        });
        Promise.all(requests).then((values) => {
            // console.log('all done!');
            setIsNIProcessing(false);
            updateRequestData();
        });
    };

    const updateRequestData = () => {
        setIsLoading(true);
        // update the result with latest data.
        GetMatchingVehicle(id).then((d) => {
            setVehicles(d.data?.requests);
            setIsLoading(false);
        }).catch((e) => {
            console.log(e);
            setIsLoading(false);
        });
    }

    const sendResponse = (data) => {
        // check if response available based on response_id
        // if response available, update the response else create new response
        const isExistingQuote = data.filter((d)=> d.response_id && d.response_id !== 0);
        if(isExistingQuote.length > 0){
            UpdateLeadResponse(id, data).then((d) => {
                if (d.code === 200) {
                    messageApi.open({
                        key,
                        type: 'success',
                        content: d.message,
                    });
                }
            }).catch((e) => {
                messageApi.open({
                    key,
                    type: 'error',
                    content: e.message,
                });
            });
        } else {
            SetLeadResponse(id, data).then((d) => {
                if (d.code === 200) {
                    messageApi.open({
                        key,
                        type: 'success',
                        content: d.message,
                    });
                }
            }).catch((e) => {
                messageApi.open({
                    key,
                    type: 'error',
                    content: e.message,
                });
            });
        }
    };

    const openQuoteModel = (index) => {
        let respCount = 0;
        // check for requests with no responses
        vehicles.forEach((item) => {
            if(item.response.length > 0){
                respCount += 1;
            }
        });

        // all the request has response, open the modal just for one request
        if(respCount === vehicles.length){
            const _v = vehicles[index];
            setReqForQuote([_v])
            setquoteRequestIndex(0);
            const otherMV = vehicles.filter((item) => item.vehicles.id !== null).map((i)=> parseInt(i.vehicles.id))
            setOtherMatchingVehicles(otherMV);
        } else {
            setReqForQuote(vehicles);
            setquoteRequestIndex(index);
            setOtherMatchingVehicles([]); // reset other matching vehicles
        }
        setIsModalOpen(true);
    }

    const _setIsModalOpen = (value) => {
        setIsModalOpen(value);
        setTimeout(() => {
            updateRequestData();
        }, 1000); // wait for responses to be update into server
        // https://trello.com/c/HpCviuZF/67-bug-add-vehicle-from-success-screen-vehicle-quote-should-be-displayed-on-lead-details

        // window.location.reload(); // to update the page after closing the modal
    }

    const pickUpAddress = getLeadAddressStr(data?.pickup_city, data?.pickup_state)
    const dropOffAddress = getLeadAddressStr(data?.dropoff_city, data?.dropoff_state)

    return (
        <><div className='lead-details-container'>
            {contextHolder}
            {isLoading && <FullSpin />}
            <BackBtn />
            {data &&
                <div className='lead-detail-section'>
                    <div className="">
                        <h1>Lead Details</h1>
                    </div>
                    <div className='flex-separate'>
                        <h4>Lead received {dayjs(data?.request_sent_at).format('MM/DD/YYYY')}</h4>
                        <h4 className='display-ib'>ID: #{id}</h4>
                    </div>
                    <hr />

                    {/* New lead Details Component */}

                    <div className='lead-view-container'>
                        <div>
                            <div className="leads-address">
                                <img
                                    className="address-image"
                                    src={pickUpImage}
                                    alt="Pickup"
                                />
                                <div className="address-detail">
                                    <div className="address-label">Pickup city</div>
                                    <div className="address-text">{pickUpAddress}</div>
                                </div>
                            </div>
                            <div className="address-separator">
                                {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
                                    <div className="address-separator-dots" key={'separator' + index} />
                                ))}
                            </div>
                            <div className="leads-address">
                                <img
                                    className="address-image"
                                    src={dropUpImage}
                                    alt="Drop_off"
                                />
                                <div className="address-detail">
                                    <div className="address-label">Drop off city</div>
                                    <div className="address-text">{dropOffAddress}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p />
                    <div className='lead-view-container'>
                        <LeadAttributes attributes={leadAttribute} />
                    </div>
                    <div>
                        <Row>
                            <Col span={24}>
                                <div>
                                    <h3>Matching Vehicles</h3>
                                    <List
                                        itemLayout="horizontal"
                                        className='ld-matching-vehicle-list'
                                        dataSource={vehicles}
                                        renderItem={(item, index) => (
                                            <List.Item
                                                className={`ld-matching-vehicle ${(item.response.length > 0) ? ((item.response[0].quotation === null) ? 'not-interested' : 'available') :''}`}
                                                actions={[
                                                    <Button size='small' type='primary' onClick={() => openQuoteModel(index)}>Quote Price</Button>
                                                    // <EditOutlined className='vehicle-action-icon' onClick={(e) => editVehicle(index)} />,
                                                    // <DeleteOutlined className='vehicle-action-icon' onClick={(e) => removeVehicle(index)} />
                                                ]}>
                                                <List.Item.Meta
                                                    avatar={<><span className='ld-badge'></span><span className={` ${item.vehicles.category} vehicle-select-list`}></span></>}
                                                    title={VehicleCategorySingularName[item.vehicles.category]}
                                                    description={<>
                                                    <div className='ld-vehicle-desc'>
                                                        <PassengerCount />{item.vehicles.passengers_count} <span className='ld-passenger'></span> 
                                                        {VehicleSeat[item.vehicles.seat_fabric] ?  <>&nbsp; <Seat /> {`${VehicleSeat[item.vehicles.seat_fabric]}`}</> : ''}
                                                        &nbsp; <span className={`vehicle-list-color-icon vehicle-color-list-${VehicleColor[item.vehicles.color]}`}></span>{VehicleColor[item.vehicles.color]}
                                                    </div>
                                                    {item.response.length > 0 ? 
                                                        (item.response[0].quotation === null ?
                                                            (<Text type="secondary">Not interested/available</Text>)
                                                            :
                                                            (
                                                                <div>
                                                                    {item.response.map((res, index) => {
                                                                        return(<div className="single-quote-price-box" key={`quote-price-box-${res.id}`}>
                                                                            <span className="quote-price-text">
                                                                            {FormatTripHours(res.hours)} : ${res.quotation}
                                                                            </span>
                                                                        </div>)
                                                                    })}
                                                                    <div className="quote-by-container">
                                                                        <span className="quote-by-text">By: {item.response[0].created_by.name}</span>
                                                                        <div className="quote-time">
                                                                            <ThinTime />
                                                                            <span className="quote-by-text">{dayjs(item.response[0].created_at).format('MM/DD/YYYY hh:mm A')}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                        : 
                                                        <Text type='danger'>Not submitted</Text>}
                                                    </>}
                                                />
                                            </List.Item>
                                        )} />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* <hr /> */}
                    <p>Not interested in this lead? Click on the button below to mark all your vehicles as "Not interested/available"</p>
                    <Button type='primary'
                        className='btn btn-primary'
                        size='large'
                        loading={isNIProcessing}
                        onClick={setNotInterested}>
                        Not interested/available
                    </Button>
                </div>}

            {isModalOpen && <QuotePrice
                req={reqForQuote}
                vehicleIndex={quoteRequestIndex}
                isModalOpen={isModalOpen}
                sendResponse={sendResponse}
                tripType={data.trip_type}
                roundTripData={roundTripData}
                otherMatchingVehicles={otherMatchingVehicles}
                setIsModalOpen={_setIsModalOpen} />}
        </div>
        </>
    )
};

export default LeadDetails;