import React from 'react'

const Event = () => {
    return (
        <svg viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1001_3132)">
                <path d="M2.21 26C1.58053 26 1.05505 25.7893 0.633579 25.3678C0.211193 24.9454 0 24.4195 0 23.79V6.31529C0 5.68582 0.211193 5.16034 0.633579 4.73887C1.05505 4.31648 1.58053 4.10529 2.21 4.10529H4.63211V1.05371H6.10589V4.10529H15.8942V1.05371H17.2626V4.10529H19.6847C20.3142 4.10529 20.8397 4.31648 21.2612 4.73887C21.6835 5.16034 21.8947 5.68582 21.8947 6.31529V23.79C21.8947 24.4195 21.684 24.945 21.2625 25.3664C20.8401 25.7888 20.3142 26 19.6847 26H2.21ZM2.21 24.6316H19.6847C19.8946 24.6316 20.0875 24.544 20.2636 24.3689C20.4387 24.1928 20.5263 23.9999 20.5263 23.79V11.789H1.36842V23.79C1.36842 23.9999 1.456 24.1928 1.63116 24.3689C1.80723 24.544 2.00018 24.6316 2.21 24.6316ZM1.36842 10.4206H20.5263V6.31529C20.5263 6.10546 20.4387 5.91252 20.2636 5.73645C20.0875 5.56129 19.8946 5.47371 19.6847 5.47371H2.21C2.00018 5.47371 1.80723 5.56129 1.63116 5.73645C1.456 5.91252 1.36842 6.10546 1.36842 6.31529V10.4206Z" fill="currentColor" />
                <path d="M11.0133 14.0806L12.1528 16.1996C12.2283 16.34 12.3662 16.436 12.5241 16.458L14.8839 16.7868L13.161 18.3763C13.0338 18.4936 12.9764 18.6682 13.009 18.838L13.4447 21.1081L11.2207 20.0141C11.0815 19.9457 10.9185 19.9457 10.7793 20.0141L8.55531 21.1081L8.99104 18.838C9.02364 18.6682 8.96615 18.4936 8.83904 18.3763L7.11973 16.7901L9.56719 16.4583C9.73014 16.4362 9.87181 16.3354 9.94609 16.1886L11.0133 14.0806Z" stroke="currentColor" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1001_3132">
                    <rect width="21.8947" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Event;