// Import Module
import React, {useState, useEffect} from 'react';
import {List , Button, message, Popover} from 'antd';
import {GetAffiliateVehicles, EditVehicle,
  CreateVehicle, DeleteVehicle
} from '../services/api';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {} from '../services/helper';
import { VehicleCategory, VehicleColor, VehicleSeat } from '../constants';

import VehicleInfoModal from '../components/vehicle_info_modal';
import { GetCookie } from '../services/helper';
// import FullSpin from '../components/full_spin';
import PassengerCount from '../assets/images/svg/PassengerCount';
import Seat from '../assets/images/svg/Seat';

const Vehicles = () => {

  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditVehicleModalOpen, setIsEditVehicleModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isVehicleLoading, setIsVehicleLoading] = useState(false);
  const [editModeVehicleIndex, setEditModeVehicleIndex] = useState(-1);
  const [editModeCVIndex, setEditModeCVIndex] = useState(-1);
  const [totalVehicle, setTotalVehicle] = useState(0); // eslint-disable-line
  const key = 'updatable';
  const id = GetCookie('AffiliateID');
  
  useEffect(() => {
    getAffVehicles();
  }, []); //eslint-disable-line

  const getAffVehicles = () => {
    GetAffiliateVehicles(id).then((d) => {
      if (d.code === 200) {
        let groupVehicles = [];
        setTotalVehicle(d.data.length);
        // Patch to remove key warning in Ant design table
        d.data.forEach((item, index) => {
          const _index = groupVehicles.findIndex((v) => v.category === item.category);
          if(_index > -1){
            groupVehicles[_index].vehicles = [...groupVehicles[_index].vehicles, item];
          } else {
            groupVehicles.push({
              category: item.category,
              vehicles: [item]
            });
          }
        });

        groupVehicles.map((item, index) => ({
          ...item,
          key: index, // Use id field as key
          index
        }));
        // sort array by category field
        // data.sort((a, b) => a.category.localeCompare(b.category));

        setVehicles(groupVehicles);
        setIsLoading(false);
      }
    }).catch((e) => {
      console.log('e');
    });
  };

  const addVehicle = (vehicle) => {
    let _vehicles = [...vehicles];
    // find the category index
    const _index = _vehicles.findIndex((v) => v.category === vehicle.category);
    // check for duplication
    if (_index > -1 && _vehicles[_index].vehicles.some((v) => 
      v.category === vehicle.category &&
      v.color === vehicle.color &&
      v.seat_fabric === vehicle.seat_fabric &&
      v.passenger_count === vehicle.passenger_count)) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Vehicle already exists!',
      });
      return;
    }
    setIsVehicleLoading(true);
    if (editModeVehicleIndex > -1) {
      EditVehicle(id, vehicle.id, vehicle).then((d) => {
        if (d.code === 200) {
          // _vehicles[editModeCVIndex].vehicles[editModeVehicleIndex] = vehicle;
          messageApi.open({
            type: 'success',
            content: 'Vehicle updated successfully!'
          });
        } 
        setIsEditVehicleModalOpen(false);
        setEditModeVehicleIndex(-1);
        setEditModeCVIndex(-1);
        setVehicles(_vehicles);
        getAffVehicles(); // in edit just update the data locally
        setIsVehicleLoading(false);
      }).catch((e) => {
        messageApi.open({
          type: 'error',
          content: e.message
        });
        setIsVehicleLoading(false);
      });
    } else {
      CreateVehicle(id, vehicle).then((d) => {
        if (d.code === 200) {
          // _vehicles.push(vehicle);
          messageApi.open({
            type: 'success',
            content: 'Vehicle created successfully!'
          });
        }
        setIsEditVehicleModalOpen(false);
        setEditModeVehicleIndex(-1);
        setEditModeCVIndex(-1);
        // setVehicles(_vehicles);
        setIsVehicleLoading(false);
        getAffVehicles();
      }).catch((e) => {
        messageApi.open({
          type: 'error',
          content: e.message
        });
        setIsVehicleLoading(false);
      });
    }
  };

  const DeletePopContent = (id, key,cvIndex, index ) =>(
    <div>
      <Button type="primary" danger onClick={() => {
        DeleteVehicle(id, key).then((d) => {
          if (d.code === 200) {
            messageApi.open({
              type: 'success',
              content: 'Vehicle deleted successfully!'
            });
            let _vehicles = [...vehicles];
            _vehicles[cvIndex].vehicles.splice(index, 1);
            // if vehicle list is empty then remove category
            if(_vehicles[cvIndex].vehicles.length === 0){
              _vehicles.splice(cvIndex, 1);
            }
            setVehicles(_vehicles);
            //set total vehicle count
            setTotalVehicle(totalVehicle - 1);
          } else {
            messageApi.open({
              type: 'error',
              content: d.message
            });
          }
        }).catch((e) => {
          messageApi.open({
            type: 'error',
            content: e.message
          });
        });
      }}>
      Yes</Button>
    </div>
  );

  return (
    <div className='p-ant-table-container vehicle-page-container'>
      <h1>Total Vehicles ({totalVehicle})</h1>
      {contextHolder}
      {/* {isLoading && <FullSpin />} */}
      {vehicles && (
        <>
        {isEditVehicleModalOpen && <VehicleInfoModal 
          isModalOpen={isEditVehicleModalOpen}
          setIsModalOpen={setIsEditVehicleModalOpen}
          addVehicleIntoList={addVehicle}
          editModeIndex={editModeVehicleIndex}
          loading={isVehicleLoading}
          messageApi={messageApi}
          vehicle={editModeVehicleIndex > -1 ? vehicles[editModeCVIndex].vehicles[editModeVehicleIndex] : null}
        />}
        <div className='p-ant-table-container vehicle-table'>
          <div className='flex-separate'>
            <h3>Vehicles</h3>
            <Button
              type='primary'
              className='btn'
              onClick={() => {
                setIsEditVehicleModalOpen(true);
                setEditModeVehicleIndex(-1);
                setEditModeCVIndex(-1);
              }}  
              >
                Add Vehicle
              </Button>
          </div>
          <List
            itemLayout="horizontal"
            dataSource={vehicles}
            className='mng-vehicle-list-container'
            loading={isLoading}
            renderItem={(item, index) => (
            <List.Item
                key={item.key}
                actions={[
                // <EditOutlined className='vehicle-action-icon' onClick={(e) => editVehicle(index)} />,
                // <DeleteOutlined className='vehicle-action-icon' onClick={(e) => removeVehicle(index)} />
                ]}>
                <List.Item.Meta
                    avatar={<span className={` ${item.category} vehicle-select-list`}></span>}
                    title={VehicleCategory[item.category]}
                    description={
                          <div>
                            {item.vehicles.map((v, i) => (
                              <div key={i} className='vehicle-list-item'>
                                <div className='flex-separate'>
                                  <div>
                                    <div className='color-wrapper'>
                                      <PassengerCount /> {v.passenger_count} 
                                    </div>
                                    {VehicleSeat[v.seat_fabric] &&
                                      <div className='color-wrapper'>
                                        <Seat /> {`${VehicleSeat[v.seat_fabric]}`}
                                      </div>
                                    }
                                    <div className='color-wrapper'>
                                      <span className={`vehicle-list-color-icon vehicle-color-list-${VehicleColor[v.color]}`}></span>{VehicleColor[v.color]}
                                    </div>
                                    
                                  </div>
                                  <div>
                                    <Popover content={DeletePopContent(id, v.id, index, i)} title="Delete Vehicle" trigger="click">
                                      <DeleteOutlined className='vehicle-action-icon' />
                                    </Popover>
                                    <EditOutlined className='vehicle-action-icon' onClick={(e) => {
                                      setIsEditVehicleModalOpen(true);
                                      setEditModeCVIndex(index);
                                      setEditModeVehicleIndex(i);
                                    }} />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                    }
                />
            </List.Item>
            )}
        />
        </div>
        </>
      )}
    </div>
  )
};

export default Vehicles;