// Import Modules
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import RouteList from './route_list';

const myTheme = {
  "token": {
    "colorPrimary": "#101010",
    "colorInfo": "#101010"
  },
  "components": {
    "Button": {
      "defaultBorderColor": "rgb(0, 0, 0)"
    }
  }
};

const App = () => {
  return (
    <div id="wrapper">
      <ConfigProvider theme={myTheme}>
        <BrowserRouter forceRefresh>
          <RouteList />
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
};

export default App;
