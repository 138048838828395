// Import Module
import React, { useEffect, useState } from 'react';
import { Spin, Select, Alert, Empty } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { GetSentQuotes, GetAffiliateStatus } from '../services/api';
import SentQuoteCard from '../components/LeadCard/sent_quote_card';

const MyResponse = () => {
  const navigate = useNavigate();
  const [harMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [sort, setSort] = useState();
  const [isPending, setIsPending] = useState(false); 
  const [filter, setFilter] = useState(null);
  const [pagination, setPagination] = useState({
    per_page_rows: 10,
    current_page: 1,
  });

  useEffect(() => {
    GetAffiliateStatus().then((d) => {
      if (d.code === 200) {
        if (d.data.affiliate.status !== 'approved') {
          // console.log('Affiliate status is not active');
          setIsPending(true);
        }
      }
    });
  }, []);

  const updateList = (_sort = sort, pg = pagination) => { // default oldest
    let params = ``;
    if (_sort) {
      params += `sort_by=${_sort}&`;
    }
    // pagination part
    params += `per_page_rows=${pg.per_page_rows}&current_page=${pg.current_page}`;
    if (filter) {
      params += `&filter=${filter}`;
    }
    getSentQuotes(params);
  };

  const getSentQuotes = (params) => {
    if (isLoading) return;
    setIsLoading(true);
    GetSentQuotes(params)
      .then((d) => {
        if (d.code === 200) {
          const preLeadsCount = data.length;
          const newLeadsCount = d?.data?.leads?.length;
          const leadsCount = preLeadsCount + newLeadsCount;
          const newLeads = d.data.leads;
          setHasMore(leadsCount < d.data.pagination.total_items);
          setData((prevData) => [...prevData, ...newLeads]);
          setTotalItems(d.data.pagination.total_items);
          setIsLoading(false);
          setPagination({
            per_page_rows: 10,
            current_page: parseInt(d.data.pagination.current_page) + 1,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }

  // Handle onClick event
  const handleOnClick = (leadId) => {
    navigate(`/lead/${leadId}`);
  }

  const handleSortChange = (value, option) => {
    const pg = {
      per_page_rows: 10,
      current_page: 1,
    };
    setSort(value);
    setData([]);
    setHasMore(true);
    setPagination(pg);
  }

  const handleFilterChange = (value, option) => {
    const pg = {
      per_page_rows: 10,
      current_page: 1,
    };
    setFilter(value);
    setData([]);
    setHasMore(true);
    setPagination(pg);
  }

  return (
    <div className='p-ant-table-container '>
      <div className='flex-separate'>
        <h1>My Responses ({totalItems})</h1>
        <div>
          <Select
            placeholder="Sort by"
            style={{
              width: 120,
            }}
            onChange={handleSortChange}
            options={[
              {
                value: '',
                label: 'None',
              },
              {
                value: 'oldest',
                label: 'Oldest',
              },
              {
                value: 'newest',
                label: 'Newest',
              },
            ]}
          />
          <Select
            placeholder="Filter by"
            style={{
              width: 120,
              marginLeft: 10,
              marginRight: 10,
            }}
            onChange={handleFilterChange}
            options={[
              {
                value: '',
                label: 'All',
              },
              {
                value: 'hired',
                label: 'Hired',
              },
              {
                value: 'closed',
                label: 'Closed',
              },
            ]}
          />
        </div>
      </div>

      <InfiniteScroll
        pageStart={0}
        loadMore={() => updateList()}
        hasMore={harMore}
        className="vehicle-list-container"
        loader={
          <Spin key={0} style={{ textAlign: "center", width: "100%" }} />
        }
      >
        <div className='masonry-container'>
          {data.map((item, index) => {
            return (
              <div className='masonry-item' key={`my-response-${index}`}>
                <SentQuoteCard leadData={item} onClick={handleOnClick} />
              </div>
            )
          })}
        </div>
      </InfiniteScroll>

      {isPending ? 
        <Alert
          message="Your account is under review."
          description="You won't receive any leads until your account is activated."
          type="warning"
          showIcon
        />
        : (
          (data.length === 0 && !isLoading) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No data available'} />
        )
      }

    </div>
  );
};

export default MyResponse;
