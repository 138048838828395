// Import Modules
import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { GetCookie } from '../services/helper';

const PrivateRoute = (props) => {
  const auth = GetCookie('AffiliateAccessToken');
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

PrivateRoute.defaultProps = {
  component: () => {},
};

export default PrivateRoute;
