// Import Module
import React from 'react';
import { elastic as Menu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';
import { LogOut } from '../services/api.js';
import { message } from 'antd';

import {
  CloudDownloadOutlined,
  ApartmentOutlined,
  // ContactsOutlined,
  PoweroffOutlined,
  // InfoCircleOutlined,
  RiseOutlined,
  TeamOutlined,
  CarOutlined,
} from '@ant-design/icons';

import { GetCookie, RemoveCookie} from '../services/helper';
import Logo from '../assets/images/price4limo-logo.jpg';
// import './burger_menu.scss';

class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true, //reactLocalStorage.get('isOpen') === 'true',
      active: false,
      menuCollapse: false,
      areMenusOpen: false,
      user: '',
    };
  }

  componentDidMount() {
    const user = GetCookie('AffiliateUser');
    this.setState({ user });
  }

  logOutUser = () => {
    // Clear all cookies
    const accessToken = GetCookie('AffiliateAccessToken');
    LogOut(accessToken).then((data) => {
      RemoveCookie('AffiliateAccessToken');
      RemoveCookie('AffiliateRefreshToken');
      RemoveCookie('AffiliateUser');
      RemoveCookie('AffiliateID');
      RemoveCookie('Zoom');
      localStorage.clear();
      window.location = '/login';
    }).catch((e) => {
      message.error(e.message);
    });;
  };

  onClick = (e) => {
    e.preventDefault();
  };

  handleStateChange = (state) => {
    // reactLocalStorage.set('isOpen', state.isOpen);
    this.setState({ isOpen: state.isOpen });
    const burgerElement = document.getElementsByClassName('bm-burger-button');
    if (burgerElement) {
      burgerElement[0].classList.toggle('menu-push');
    }
    const element = document.getElementById('outer-container');
    element.classList.toggle('push-body');

    const { menuCollapse } = this.state;
    this.setState({
      menuCollapse: !menuCollapse,
    });
  };

  render() {
    const { isOpen, menuCollapse, areMenusOpen } = this.state;

    /** ********** closeAllMenusOnEsc function is used for to solve Home & End keybord key not working issue ************ */

    const closeAllMenusOnEsc = (e) => {
      e = e || window.event;
      if (
        e.key === 'Home' ||
        e.keyCode === 37 ||
        e.key === 'End' ||
        e.keyCode === 35
      ) {
        this.setState({ areMenusOpen: false });
      }
    };

    return (
      <div className='menu-container'>
        <div className='menu-blury-container' />
        <Menu
          className={`my-menu ${menuCollapse ? 'collapse-on' : 'collapse-off'}`}
          customOnKeyDown={closeAllMenusOnEsc}
          isOpen={isOpen}
          isOpens={areMenusOpen}
          role='presentation'
          onStateChange={this.handleStateChange}
          noOverlay
          disableOverlayClick
          customBurgerIcon={<span>☰</span>}
        >
          <div className='logo-container' role='presentation'>
            <img
              alt='logo'
              className='img-responsive company-menu-logo'
              src={Logo}
            />
          </div>

          {isOpen ? (
            <div className='company-user-details'>
              <div className='company-user-name'>Hi, {this.state.user}</div>
            </div>
          ) : null}

          <NavLink
            // activeClassName="burger-menu-active"
            className='bm-item-list'
            // className={({ isActive }) => (isActive ? "burger-menu-active" : 'bm-item-list')}
            to='/leads'
          >
            <RiseOutlined className='site-form-item-icon' />
            <span className='nav-title' role='presentation'>
              Leads
            </span>
          </NavLink>

          <NavLink className='bm-item-list' to='/responses' exact='true'>
            <CloudDownloadOutlined className='site-form-item-icon' />
            <span className='nav-title'>Quotes Sent</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/vehicles' exact='true'>
            <CarOutlined className='site-form-item-icon' />
            <span className='nav-title'>Vehicles</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/company-info' exact='true'>
            <ApartmentOutlined className='site-form-item-icon' />
            <span className='nav-title'>Company Information</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/team-management' exact='true'>
            <TeamOutlined className='site-form-item-icon' />
            <span className='nav-title'>Team Management</span>
          </NavLink>

          <span
            className='bm-item-list hover-off-link'
            id='logout'
            role='presentation'
            onClick={this.logOutUser}
          >
            <PoweroffOutlined className='site-form-item-icon' />
            <span className='nav-title'>Log Out</span>
          </span>
        </Menu>
      </div>
    );
  }
}

BurgerMenu.propTypes = {};

BurgerMenu.defaultProps = {};

// export default withRouter(BurgerMenu);
export default BurgerMenu;
