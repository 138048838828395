// Import Module
import React, {useEffect, useState} from 'react';
import Contact from '../components/contact';

import { GetAffiliateContacts } from '../services/api';
import { GetCookie } from '../services/helper';
import FullSpin from '../components/full_spin';

const TeamManagement = () => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const id = GetCookie('AffiliateID');

  useEffect(() => {
    GetAffiliateContacts(id).then((d) => {
      if (d.code === 200) {
        // sort to display the primary contact first
        d.data.sort((a, b) => {
          if (a.is_primary) {
            return -1;
          }
          return 1;
        });
        setData(d.data);
        setIsLoading(false);
      }
    }).catch((e) => {
      console.log('e');
    });  
  }, [id]);

  const saveContact = (data) => {
    setData(data);
  }
  
  return (
    <div className='p-ant-table-container team-mg-container'>
      <h1>Team Management</h1>
      {isLoading && <FullSpin />}
      <div className='add-affiliate-container'>
        {data.length > 0 &&
          <Contact 
            isPartialForm={true}
            saveContact={saveContact} 
            contactData={data}/>
        }
      </div>
    </div>
  )
};

export default TeamManagement;