import React from 'react'

const ThinTime = () => {
    return (
        <svg viewBox="0 0 48 48" height="25" width="25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0554 13.6066C19.1109 12.2332 21.5276 11.5001 23.9997 11.5C27.3139 11.5036 30.4913 12.8217 32.8348 15.1652C35.1784 17.5087 36.4965 20.6863 36.5 24.0005C36.4999 26.4726 35.7668 28.8892 34.3934 30.9446C33.0199 33.0002 31.0676 34.6024 28.7835 35.5485C26.4995 36.4946 23.9861 36.7421 21.5614 36.2598C19.1366 35.7775 16.9093 34.587 15.1612 32.8388C13.413 31.0907 12.2225 28.8634 11.7402 26.4386C11.2579 24.0139 11.5054 21.5005 12.4515 19.2165C13.3976 16.9324 14.9998 14.9801 17.0554 13.6066Z" stroke="currentColor" strokeLinecap="round" />
            <path d="M31 26H24C23.7348 26 23.4804 25.8815 23.2929 25.6705C23.1054 25.4595 23 25.1734 23 24.875V17" stroke="currentColor" strokeLinecap="round" />
        </svg>
    )
}

export default ThinTime;