import React, { useState } from 'react';

const TripDetails = ({ tripDetail }) => {

    const [readMore, setReadMore] = useState(false);

    const handleReadMore = () => {
        setReadMore(!readMore);
    }

    return (
        <div>
            <div className='text-header'>Trip details (Approx. 59-67 Passenger)</div>
            <div className="value-text">
                {readMore ? tripDetail : tripDetail?.slice(0, 150)}
                {readMore ? <>
                    {'   '}
                    <span
                        className="read-more-text"
                        onClick={(e) => {
                            handleReadMore();
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                        }}
                    >
                        Read less
                    </span>
                </> : tripDetail.length > 150 && (
                    <>
                        {'...  '}
                        <span
                            className="read-more-text"
                            onClick={(e) => {
                                handleReadMore();
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                        >
                            Read more
                        </span>
                    </>
                )}
            </div>
        </div>
    )
}

export default TripDetails;