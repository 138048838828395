import React from 'react'

const ClockIcon = () => {
    return (
        <svg viewBox="0 0 14 14" height="20" width="20" fill="none" >
            <path d="M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4982 5.27665 12.8128 3.62441 11.5942 2.40582C10.3756 1.18722 8.72335 0.50182 7 0.5ZM7 12.5C5.91221 12.5 4.84884 12.1774 3.94437 11.5731C3.0399 10.9687 2.33495 10.1098 1.91867 9.10476C1.50238 8.09977 1.39347 6.9939 1.60568 5.927C1.8179 4.86011 2.34173 3.8801 3.11092 3.11091C3.8801 2.34172 4.86011 1.8179 5.92701 1.60568C6.9939 1.39346 8.09977 1.50238 9.10476 1.91866C10.1098 2.33494 10.9687 3.03989 11.5731 3.94436C12.1774 4.84883 12.5 5.9122 12.5 7C12.4983 8.45818 11.9184 9.85617 10.8873 10.8873C9.85617 11.9184 8.45819 12.4983 7 12.5ZM10.2812 8C10.2812 8.13261 10.2286 8.25979 10.1348 8.35355C10.041 8.44732 9.91386 8.5 9.78125 8.5H6.66558C6.53297 8.5 6.40579 8.44732 6.31202 8.35355C6.21825 8.25979 6.16558 8.13261 6.16558 8V3.75285C6.16558 3.62024 6.21825 3.49306 6.31202 3.3993C6.40579 3.30553 6.53297 3.25285 6.66558 3.25285C6.79818 3.25285 6.92536 3.30553 7.01913 3.3993C7.1129 3.49306 7.16558 3.62024 7.16558 3.75285V7.5H9.78125C9.91386 7.5 10.041 7.55268 10.1348 7.64645C10.2286 7.74021 10.2812 7.86739 10.2812 8Z" fill="currentColor" />
        </svg>
    )
}

export default ClockIcon